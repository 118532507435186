import React, { useState } from 'react';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import Helmet from 'react-helmet';
import { Box } from '@mui/system';
import { Typography, Button, Grid, InputAdornment, IconButton, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm, FormProvider } from 'react-hook-form';
import EKasiDialog from '../../common/components/EKasiDialog';
import FormInput from '../../common/FormInput';
import luhn from 'luhn';
import Link from '@mui/material/Link';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Login = ({ login }) => {
    const methods = useForm();
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [visiblePassword, setPasswordVisibility] = useState(false);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const location = useLocation();
    const { id } = useParams();

    const onPasswordChanged = (event) => {
        const password = event.target.value;
        setPassword(password);
    }

    const handleClickShowPassword = () => {
      setPasswordVisibility(!visiblePassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    const onUsernameChanged = (event) => {
        const idText = event.target.value;
        setUsername(idText)
    }

    const onSubmit = (usernameP, passwordP) => {
        if(usernameP.length === 13 && luhn.validate(usernameP) && privacyPolicyAccepted) {
          setLoading(true);
          login(usernameP, passwordP, (res) => {
            setLoading(false);
            if(location.pathname.includes("checkout")) {
              navigate(`/checkout/${id}/onboard`, { state: {loan: location.state.loan} });
              return
            }
            navigate("/dashboard");
          }, (error) => {
            setLoading(false);
            showDialog(error.code, error.data.msg, "", Strings.ok, null, null);
          })
        } else {
          showDialog(Strings.loginValidation,Strings.loginEitherIdOrPrivacy, "", Strings.ok, null, null);
        }
    }

    return(
        <>
            <Helmet>
                <title>{Strings.login}</title>
            </Helmet>

          <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
          
          <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: Colours.white, padding: 30, marginTop: 10,  marginBottom: 10}}>
          <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 30, marginTop: 10,  marginBottom: 10}}>

          <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={'left'} >{Strings.login}</Typography>
          <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => {
            onSubmit(username, password);
          })}>
            <Grid container spacing={3}>
            <FormInput helperText={Strings.usernameHelper} onChange={onUsernameChanged} required name="username" label="Username"/>
            </Grid>
            <Grid container spacing={3}>
             <FormInput
              required 
              name="password"
              label="Password"
             
             InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
             type={!visiblePassword ? "password" : "text"}
             onChange={onPasswordChanged}
                 />
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Link style={{ padding: 10 }} target={"_blank"} href="/support">
                  {Strings.forgottenPassword}
                </Link>
              </Grid>
              <Grid item>
                <Link target={"_blank"} href="/privacy">
                  {Strings.privacyPolicy}
                </Link>
              </Grid>
              <Grid item>
                <Link target={"_blank"} href="/terms">
                  {Strings.termsOfUse}
                </Link>
              </Grid>
            </Grid>
            <FormControlLabel style={{paddingTop: 0, paddingBottom: 5, paddingLeft: 10}}  required control={<Checkbox defaultChecked={false} onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)} />} label={Strings.tcConfirmation} />
            {
              isLoading ? 
                <Box style={{ display: 'flex', flex: 1, justifyContent: 'center',}}>
                    <CircularProgress variant="indeterminate"/>
                </Box>
              :
              <Box style={{ display: 'flex', flex: 1, justifyContent: 'center',}}>
                <Button type="submit" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth  disableElevation variant="contained">{Strings.login}</Button>

                </Box>
            }
            </form>
            </FormProvider>
            </Box>
            <Box/>
            </Box>
        
        </>
    );


};

export default Login;
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import { IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import EKasiAPIClient from "../../network/EkasiAPIClient";
import { ThumbUpSharp } from "@mui/icons-material";
import EKasiLoader from "../../common/components/EKasiLoader";


const SubheadingWithBody = ({heading, body}) => (
    <Typography sx={{ textAlign: 'left', p: 5, typography: 'h6', color: Colours.viridianGreen, fontWeight: 'bold' }}>
            {heading}
            <Typography sx={{ typography: 'body', color: Colours.black, fontWeight: '300' }}>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>           
            </Typography> 
    </Typography>
);

export const ArticleDetials = () => {
    const articleDetails = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [isLiked, setLike] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const article = React.useState(articleDetails.state.article);

    useEffect(() => {
        if(article.length > 0 && !isRead) {
            readArticle(article[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article])

    if (article.length <= 0) {
        return null
    }
    
    const likeArticle = (id) => {
        if(article.length > 0) {
            setLoading(true);
            EKasiAPIClient.likeArticle(id,(res) => {
                setLike(true)
                setLoading(false);
            }, () => {
                setLike(false)
                setLoading(false);
            });
        }
    }

    const readArticle = (id) => {
        if(article.length > 0) {
            EKasiAPIClient.readArticle(id,() => {
                setIsRead(true);
            }, () => {
                setIsRead(false);
            });
        }
    }

    return (
        <>
            <Helmet>
                <title> {article[0].heading} </title>
                <meta name="description" content={article[0].content_raw.slice(0,150)} />
            </Helmet>
            <EKasiLoader open={isLoading}/>
            <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
                <IconButton disabled={isLiked} color="primary" onClick={() => {likeArticle(article[0].id)}} >
                    <ThumbUpSharp size={"medium"} />
                </IconButton>
                <SubheadingWithBody heading={article[0].heading} body={article[0].content_html} />
            </Box>
        </>
    )
}

export default ArticleDetials;
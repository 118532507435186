export default class Globals {
    static iRate = 5.00; // % monthly
    static baseInitiationFee = 74.35;
    static maxInitionPercentage = 15;
    static initiationFeeProfitMarginPercentage = 7.0; // TODO: Add to the backend
    static maxServiceFee = 57.00;
    static daysInAMonth = 30;
    static layByDepositPercent = 30;
    static incomeBucket = 'proof_of_income';
    static idBucket = 'id';
    static ncrNumber = "NCRCP16640";

    static minLoan = 500.00;
    static maxLoan = 4000.00;

    static minLoanTerm = 5;
    static maxLoanTerm = 30;

    static minCheckoutTerm = 1;
    static maxCheckoutTerm = 3;

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function EKasiDialog({open, handleOpen, title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton  }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    
    handleOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            negativeTitle !== "" ?
                <Button autoFocus onClick={(event) => {
                  if(handleNegativeResponseButton !== null) {
                    handleNegativeResponseButton(event)
                  }
                  handleClose()
                }}>
                    {negativeTitle}
                </Button> 
            : null
          }
          {
            positiveTitle !== "" ?
            <Button onClick={(event) => {
              if(handlePositiveResponseButton !== null) {
                handlePositiveResponseButton(event)
              }
              handleClose()
            }} autoFocus>
                {positiveTitle}
            </Button>
            : null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, {useEffect, useState} from 'react';
import 'react-phone-number-input/style.css'
import { useForm, FormProvider } from 'react-hook-form';
import { Typography, Grid, Button, TextField, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import Colours from '../../common/Colours';
import Strings from '../../localization/Strings';
import useCheckMobile from '../../common/useCheckMobile';
import NumberFormat from 'react-number-format';
import { Box } from '@mui/system';
import Globals from '../../common/Globals';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import EKasiLoader from '../../common/components/EKasiLoader';
import EKasiDialog from '../../common/components/EKasiDialog';
import EKasiToolTip from '../../common/components/EKasiToolTip';
import LocalizedStrings from '../../localization/Strings';

const AffordabilityForm = ({ onPrev, onSubmit, defaultData}) => {
    const methods = useForm();
    const isMobile = useCheckMobile();

    const [netIncome, setNetIncome] = useState("");
    const [accomodation, setAccomodation] = useState("");
    const [transport, setTransport] = useState("");
    const [food, setFood] = useState("");
    const [education, setEducation] = useState("");
    const [medical, setMedical] = useState("");
    const [waterElectricity, setWaterElectricity] = useState("");
    const [maintainence, setMaintainence] = useState("");
    const [creditCard, setCreditCard] = useState("");
    const [personalLoan, setPersonalLoan] = useState("");

    const [showProceedButton, setShowProceedButton] = useState(true);
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") 

    const [isLoading, setLoading] = useState(false);


    const [creditCheckAcceptance, setCreditCheckAcceptance] = useState(false);
    const [dataDeclaration, setDataDeclaration] = useState(false);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState("");
    const [positiveHandler, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [applicationData, updateApplicationData] = useState({});


    useEffect(() => {
      // eslint-disable-next-line valid-typeof
      if(typeof defaultData.affordability !== "undefined" || isUserDashboard) {
        setShowProceedButton(true);
        setAccomodation(defaultData?.affordability?.accomodation || defaultData?.customer?.affordability?.accomodation)
        setNetIncome(defaultData?.affordability?.netIncome || defaultData?.customer?.affordability?.net_income)
        setTransport(defaultData?.affordability?.transport || defaultData?.customer?.affordability?.transport)
        setEducation(defaultData?.affordability?.education || defaultData?.customer?.affordability?.education)
        setMaintainence(defaultData?.affordability?.maintainence || defaultData?.customer?.affordability?.maintainence)
        setMedical(defaultData?.affordability?.medical || defaultData?.customer?.affordability?.medical)
        setWaterElectricity(defaultData?.affordability?.waterElectricity || defaultData?.customer?.affordability?.water_electricity)
        setFood(defaultData?.affordability?.food || defaultData?.customer?.affordability?.food)
        setCreditCard(defaultData?.affordability?.creditCard || defaultData?.customer?.affordability?.credit_card)
        setPersonalLoan(defaultData?.affordability?.personalLoan || defaultData?.customer?.affordability?.personal_loan)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    const negativeButtonHandler = (event) => {
      if(negativeHandler === "DECLINE_OFFER_CALLBACK") {
        
      }

      setNegativeHandler("");
    }

    const positiveButtonHandler = (event) => {
      if(positiveHandler === "OK_CALLBACK") {

      } else if(positiveHandler === "ACCEPT_OFFER_CALLBACK") {
        onSubmit(applicationData);
      }

      setPositiveHandler("");
    }


    const preassess = (data) => {
      setLoading(true);
      EKasiAPIClient.preassess(data, (response) => {
        setLoading(false);
        if(response.code === "APPROVED") {
          const amount = 3000//response?.data?.loan.amount;
          const totalRepayment = response?.data?.loan.total_repayment;
          const days = response?.data?.loan.term_in_days;
          const interestRate = response?.data?.loan.interest_rate;
          const initiationFee = response.data?.loan.initiation_fee;
          const serviceFee = response.data?.loan.service_fee;
          const fees = response.data?.loan.fees;
          onSubmit({affordability: data.affordability, loan: { ...data.loan, amount, totalRepayment, days, interestRate, initiationFee, serviceFee, fees }});
        } else if(response.code === "DECLINED" && response?.data?.loan !== undefined) {
          const amount = response?.data?.loan?.amount;
          const totalRepayment = response?.data?.loan?.total_repayment;
          const days = response?.data?.loan?.term_in_days;
          const interestRate = response?.data?.loan?.interest_rate;
          const initiationFee = response.data?.loan?.initiation_fee;
          const serviceFee = response.data?.loan?.service_fee;
          const fees = response.data?.loan?.fees;

          updateApplicationData({affordability: data.affordability, loan: { ...data.loan, amount, totalRepayment, days, interestRate, initiationFee, serviceFee, fees }})
          showDialog(Strings.affordabilityAssesment, Strings.weWouldLikeToOfferYou.replace("%1", `R ${amount.toFixed(2)}`).replace("%2", `R ${totalRepayment.toFixed(2)}`), Strings.decline, Strings.accept, "DECLINE_OFFER_CALLBACK", "ACCEPT_OFFER_CALLBACK");          
        } else {
          showDialog(Strings.affordabilityAssesment, Strings.weCantGiveYouAmount, "", Strings.ok, "", "OK_CALLBACK");
          // TODO: Just stay in the screen
        }
        }, (error) => {
          setLoading(false);
          showDialog(error.code, error.data.msg, "", "Ok", null, null);
        })
    }

  return (
    <>
          <EKasiLoader open={isLoading}/>
          <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeButtonHandler} handlePositiveResponseButton={positiveButtonHandler} />

          <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{Strings.affordabilityAssesment}</Typography>
          <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => {
            if(isUserDashboard) {
              const updatedCustomerData = {
                  ...defaultData.customer,
                  affordability: { 
                    net_income:  Number(Number(Globals.replaceAll(netIncome, ",", "")).toFixed(2)), 
                    accomodation: Number(Number(Globals.replaceAll(accomodation, ",", "")).toFixed(2)),
                    education: Number(Number(Globals.replaceAll(education, ",", "")).toFixed(2)),
                    maintainence: Number(Number(Globals.replaceAll(maintainence, ",", "")).toFixed(2)),
                    medical: Number(Number(Globals.replaceAll(medical, ",", "")).toFixed(2)),
                    water_electricity: Number(Number(Globals.replaceAll(waterElectricity, ",", "")).toFixed(2)),
                    food: Number(Number(Globals.replaceAll(food, ",", "")).toFixed(2)), 
                    credit_card: Number(Number(Globals.replaceAll(creditCard, ",", "")).toFixed(2)),
                    personal_loan: Number(Number(Globals.replaceAll(personalLoan, ",", "")).toFixed(2)),
                    transport: Number(Number(Globals.replaceAll(transport, ",", "")).toFixed(2))
                  }
              };
              onSubmit(updatedCustomerData);
              return
            }
            preassess({ ...defaultData, affordability: { 
              netIncome: Number(Globals.replaceAll(netIncome,",", "")).toFixed(2), 
              accomodation: Number(Globals.replaceAll(accomodation,",", "")).toFixed(2),
              education: Number(Globals.replaceAll(education,",", "")).toFixed(2),
              maintainence: Number(Globals.replaceAll(maintainence,",", "")).toFixed(2),
              medical: Number(Globals.replaceAll(medical,",", "")).toFixed(2),
              waterElectricity: Number(Globals.replaceAll(waterElectricity,",", "")).toFixed(2),
              food: Number(Globals.replaceAll(food,",", "")).toFixed(2), 
              creditCard: Number(Globals.replaceAll(creditCard,",", "")).toFixed(2),
              personalLoan: Number(Globals.replaceAll(personalLoan,",", "")).toFixed(2),
              transport: Number(Globals.replaceAll(transport,",", "")).toFixed(2)
              }
            });
          })}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.netIncome || defaultData?.customer?.affordability?.net_income} 
                    customInput={TextField}
                    name='net-income' 
                    helperText={Strings.netIncomeHelper}
                    label={Strings.netIncome}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={netIncome}
                    decimalScale={2}
                    onValueChange={(vals) => setNetIncome(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.accomodation || defaultData?.customer?.affordability?.accomodation} 
                    customInput={TextField}
                    name='accomodation' 
                    label={Strings.accomodationExpenses}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={accomodation}
                    decimalScale={2}
                    onValueChange={(vals) => setAccomodation(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.transport || defaultData?.customer?.affordability?.transport} 
                    customInput={TextField}
                    name='transport' 
                    label={Strings.transportExpenses}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={transport}
                    decimalScale={2}
                    onValueChange={(vals) => setTransport(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.food} 
                    customInput={TextField}
                    name='food' 
                    label={Strings.foodExpenses}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={food}
                    decimalScale={2}
                    onValueChange={(vals) => setFood(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.education || defaultData?.customer?.affordability?.education} 
                    customInput={TextField}
                    name='education' 
                    label={Strings.educationExpenses}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={education}
                    decimalScale={2}
                    onValueChange={(vals) => setEducation(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.medical || defaultData?.customer?.affordability?.medical} 
                    customInput={TextField}
                    name='medical' 
                    label={Strings.medicalExpenses}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={medical}
                    decimalScale={2}
                    onValueChange={(vals) => setMedical(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.waterElectricity || defaultData?.customer?.affordability?.water_electricity} 
                    customInput={TextField}
                    name='water-electricity' 
                    label={Strings.waterElectricity}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={waterElectricity}
                    decimalScale={2}
                    onValueChange={(vals) => setWaterElectricity(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.maintainence || defaultData?.customer?.affordability?.maintainence} 
                    customInput={TextField}
                    name='maintainence' 
                    label={Strings.maintainence}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={maintainence}
                    decimalScale={2}
                    onValueChange={(vals) => setMaintainence(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.personalLoan || defaultData?.customer?.affordability?.personal_loan} 
                    customInput={TextField}
                    name='personal-loan' 
                    helperText={Strings.personalLoansHelp}
                    label={Strings.personalLoan}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={personalLoan}
                    decimalScale={2}
                    onValueChange={(vals) => setPersonalLoan(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <NumberFormat
                    style={{padding:10}}
                    fullWidth
                    defaultValue={defaultData?.affordability?.creditCard || defaultData?.customer?.affordability?.credit_card} 
                    customInput={TextField}
                    name='credit-card' 
                    helperText={Strings.creditCardDebtHelp}
                    label={Strings.creditCard}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={creditCard}
                    decimalScale={2}
                    onValueChange={(vals) => setCreditCard(vals.formattedValue)}
                    InputProps={{
                      startAdornment: <span>R</span>
                    }}
                  />
                </Grid>
            </Grid>

          {
            isUserDashboard ? null :
            <FormGroup>
            <FormControlLabel style={{padding: 10}}  required control={<Checkbox defaultChecked={false}  onChange={(e) => setDataDeclaration(e.target.checked)} />} label={Strings.declaration} />
            <FormControlLabel style={{padding: 10}}  required control={
              <EKasiToolTip placement="right-start" arrow={true}
              title={
                <React.Fragment>
                  <Typography dangerouslySetInnerHTML={{ __html:  LocalizedStrings.creditConsent }} color="inherit"/>
                </React.Fragment>
              }
            >
              <Checkbox defaultChecked={false} onChange={(e) => setCreditCheckAcceptance(e.target.checked)} />
            </EKasiToolTip>
            
            } label={Strings.creditCheck} />
            </FormGroup>
          }
        

      

          <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',}}>
              {   isUserDashboard ? null :
                  <Button onClick={onPrev} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.back}</Button>
              }
             {
              (showProceedButton && dataDeclaration && creditCheckAcceptance) || isUserDashboard ? 
              <Button type="submit" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{isUserDashboard ? Strings.update : Strings.next}</Button>
              : null
            }
          </Box>          

          </form>
          </FormProvider>
    </>
  );
}

export default AffordabilityForm;

import axios from "axios";
import moment from "moment";
import Globals from "../common/Globals";

export default class EKasiAPIClient {
    static baseUrl = process.env.REACT_APP_BASE_URL;

    static async upload(file, bucket, authToken, loanId, onSuccess, onError) {
      const data = new FormData();
      const url = `${EKasiAPIClient.baseUrl}/upload`;

            
      data.append('file', file);
      data.append('filename', loanId);
      data.append('bucket', bucket);

      if(bucket === Globals.incomeBucket) {
        data.append('bank', bucket);
        data.append('name', bucket);
        data.append('id_number', bucket);
      }

      var config = {
        method: 'post',
        url: url,
        headers: { 
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }

    static async authenticate(username, password, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/authenticate`;
      var data = JSON.stringify({"id_number":username,"password": password});

      var config = {
        method: 'post',
        url: url,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }

    static async updateLoanStatus(token, loanId, loanStatus, isCheckout, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/update-loan-status`; 
      const body = {
        "type": isCheckout ? "checkout" : "loan",
        "loan_id": loanId,
        "status": loanStatus,
      };

      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
  }

    static async userExists(username, onSuccess, onError) {
          const url = `${EKasiAPIClient.baseUrl}/account/${username}`;  
          var config = {
            method: 'get',
            url: url,
            headers: { }
          };

        axios(config)
        .then(function (response) {
          onSuccess(response.data)
        })
        .catch(function (error) {
          onError(error.response.data)
        });
    }

    static async verifyAffordability(token, loanId, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/verify-affordability/${loanId}`;  
      var config = {
        method: 'post',
        url: url,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
         }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }

    static async submitSecurityAnswers(token, assessmentId, answers, onSuccess, onError) {
      const body = {
        "assessment_id": assessmentId,
        "answers": answers
      };
      const url = `${EKasiAPIClient.baseUrl}/answer-questions`;  
      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
         }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
} 

    static async getCustomer(token, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/customer`;  
      var config = {
        method: 'get',
        url: url,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
         }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
}

static async getLoanFees(token, onSuccess, onError) {
  const authToken = token === "" || token === null || token === undefined ?  `Basic ${process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN}` : `Bearer ${token}`;
  const url = `${EKasiAPIClient.baseUrl}/loan-fees`;  
  var config = {
    method: 'get',
    url: url,
    headers: {
      'Authorization': `${authToken}`,
      'Content-Type': 'application/json'
     }
  };

  axios(config)
  .then(function (response) {
    onSuccess(response.data)
  })
  .catch(function (error) {
    onError(error.response.data)
  });
}

static async getCheckout(token, checkoutId, onSuccess, onError) {
  const authToken = token === "" || token === null || token === undefined ?  `Basic ${process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN}` : `Bearer ${token}`;
  const url = `${EKasiAPIClient.baseUrl}/checkout/${checkoutId}`;  
  var config = {
    method: 'get',
    url: url,
    headers: {
      'Authorization': `${authToken}`,
      'Content-Type': 'application/json'
     }
  };

  axios(config)
  .then(function (response) {
    onSuccess(response.data)
  })
  .catch(function (error) {
    onError(error.response.data)
  });
}

static async requestVerification(number, onSuccess, onError) {
  const url = `${EKasiAPIClient.baseUrl}/request-verification`;
  const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN ;

  const body = {
      "type": "SMS",
      "phone": number
  }

  var config = {
    method: 'post',
    url: url,
    data:  JSON.stringify(body),
    headers: {
      'Authorization': `Basic ${adminToken}`,
      'Content-Type': 'application/json'
    }
  };
  axios(config)
  .then(function (response) {
    onSuccess(response.data)
  })
  .catch(function (error) {
    onError(error.response.data)
  });
}

    static async completeVerification(number, code, token, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/complete-verification`;
      const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN ;

      const body = {
          "type": "SMS",
          "phone": number,
          "code": code,
          "token": token
      }

      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Authorization': `Basic ${adminToken}`,
          'Content-Type': 'application/json'
        }
      };
      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }

    static async application(applicationData, isCheckout, onSuccess, onError) {
        const url = `${EKasiAPIClient.baseUrl}/application`;
        var body = {};

        if(isCheckout) {
          body = {
            "type": "checkout",
            "checkout": {
              "id": applicationData.loan.id,
              "created_at": "",
              "id_number":  "",
              "amount": applicationData.loan.amount * Globals.daysInAMonth,
              "term_in_days": applicationData.loan.days,
              "interest_rate": applicationData.loan.interestRate,
              "fees": applicationData.loan.fees,
              "status": "", 
              "purpose": "EMERGENCY EXPENSE",
              "currency": "ZAR",
              "loan_applicants_image_base64": applicationData.loan_applicants_image_base64,
  
              "initiation_fee": applicationData.loan.initiationFee,
              "interest_amount_per_day": applicationData.loan.interestPerDay,
              "service_fee": applicationData.loan.serviceFee,
              "total_repayment": applicationData.loan.totalRepayment,
              "truid_collection_id": "",
            },
          }
        } else {
          body = {
            "type": "loan",
            "loan": {
              "created_at": "",
              "id_number":  "",
              "amount": applicationData.loan.amount,
              "term_in_days": applicationData.loan.days,
              "interest_rate": applicationData.loan.interestRate,
              "fees": applicationData.loan.fees,
              "status": "", 
              "purpose": "EMERGENCY EXPENSE",
              "currency": "ZAR",
              "loan_applicants_image_base64": applicationData.loan_applicants_image_base64,
  
              "initiation_fee": applicationData.loan.initiationFee,
              "interest_amount_per_day": applicationData.loan.interestPerDay,
              "service_fee": applicationData.loan.serviceFee,
              "total_repayment": applicationData.loan.totalRepayment,
              "truid_collection_id": "",
            },
          }
        }

        var config = {
          method: 'post',
          url: url,
          data:  JSON.stringify(body),
          headers: {
            'Authorization': `Bearer ${applicationData.token}`,
            'Content-Type': 'application/json'
          }
        };
        axios(config)
        .then(function (response) {
          onSuccess(response.data)
        })
        .catch(function (error) {
          onError(error.response.data)
        });
    }

    static async preassess(applicationData, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/pre-assess`;
      const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN 

      const body = {
        "affordability": {
          "net_income": Number(applicationData?.affordability?.netIncome) || applicationData.customer.affordability.net_income,
          "accomodation": Number(applicationData?.affordability?.accomodation || applicationData.customer.affordability.accomodation),
          "transport": Number(applicationData?.affordability?.transport || applicationData.customer.affordability.transport),
          "food": Number(applicationData?.affordability?.food || applicationData.customer.affordability.food),
          "maintainence":  Number(applicationData?.affordability?.maintainence || applicationData.customer.affordability.accmaintainenceomodation),
          "education": Number(applicationData?.affordability?.education || applicationData.customer.affordability.education),
          "medical": Number(applicationData?.affordability?.medical || applicationData.customer.affordability.medical),
          "water_electricity": Number(applicationData?.affordability?.waterElectricity || applicationData.customer.affordability.accomodatwater_electricityion),
          "credit_card": Number(applicationData?.affordability?.creditCard || applicationData.customer.affordability.credit_card),
          "personal_loan": Number(applicationData?.affordability?.personalLoan || applicationData.customer.affordability.personal_loan)
        },
        "loan": {
          "created_at": `${moment().unix()}`,
          "id_number":  "",

          "amount": applicationData.loan.amount,
          "term_in_days": applicationData.loan.days,
          "interest_rate": applicationData.loan.interestRate,
          "fees": applicationData.loan.fees,
          "initiation_fee": applicationData.loan.initiationFee,
          "interest_amount_per_day": applicationData.loan.interestPerDay,
          "service_fee": applicationData.loan.serviceFee,
          "total_repayment": applicationData.loan.totalRepayment,

          "status": "", 
          "purpose": "EMERGENCY EXPENSE",
          "currency": "ZAR",
          "loan_applicants_image_base64": "",
          "truid_collection_id": "",
        }
      }

      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${adminToken}`
        }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }

    static async newApplication(applicationData, isCheckout, onSuccess, onError) {
          const url = `${EKasiAPIClient.baseUrl}/new-application`;
          var body = {};

          if(isCheckout) {
            body = {
              "type": "checkout",
              "account": {
                  "created_at": `${moment().unix()}`,
                  "id_number": applicationData.account.username,
                  "password": applicationData.account.password,
                  "last_logged_in_at": `${moment().unix()}`,
                  "status": "",
                  "password_attempts": 0
              },
              "customer": {
                  "account_id": "",
                  "loans": [],
                  "email": applicationData.account.email,
                  "name": applicationData.account.firstname,
                  "last_name": applicationData.account.lastName,
                  "phone_number": applicationData.account.phone,
          
                  "address_1": applicationData.address.address1,
                  "city": applicationData.address.city,
                  "province": applicationData.address.province,
                  "postal_code": applicationData.address.zip,
                  "country": applicationData.address.country,
          
                  "is_number_verified": false,
                  "is_id_verified": false,
          
                  "employer_name": applicationData.account.employerName,
                  "employment_industry": applicationData.account.employmentIndustry,
                  "employment_status": applicationData.account.employmentStatus,
                  "salary_paid_in_account": Number(applicationData.account.salary),
                  "bank_name": applicationData.account.bank,
          
                  "affordability": {
                      "net_income": Number(applicationData.affordability.netIncome),
                      "accomodation": Number(applicationData.affordability.accomodation),
                      "transport": Number(applicationData.affordability.transport),
                      "food": Number(applicationData.affordability.food),
                      "maintainence":  Number(applicationData.affordability.maintainence),
                      "education": Number(applicationData.affordability.education),
                      "medical": Number(applicationData.affordability.medical),
                      "water_electricity": Number(applicationData.affordability.waterElectricity),
                      "credit_card": Number(applicationData.affordability.creditCard),
                      "personal_loan": Number(applicationData.affordability.personalLoan)
                  }
              },
              "checkout": {
                "id": applicationData.loan.id,
                "id_number":  applicationData.account.username,
                "amount": applicationData.loan.amount,
                "term_in_days": applicationData.loan.days * Globals.daysInAMonth,
                "interest_rate": applicationData.loan.interestRate,
                "fees": applicationData.loan.fees,
                "purpose": "EMERGENCY EXPENSE",
                "currency": "ZAR",
                "loan_applicants_image_base64": applicationData.loan_applicants_image_base64,
        
                "initiation_fee": applicationData.loan.initiationFee,
                "interest_amount_per_day": applicationData.loan.interestPerDay,
                "service_fee": applicationData.loan.serviceFee,
                "total_repayment": applicationData.loan.totalRepayment
            }
          };

          } else {
            body = {
              "type": "loan",
              "account": {
                  "created_at": `${moment().unix()}`,
                  "id_number": applicationData.account.username,
                  "password": applicationData.account.password,
                  "last_logged_in_at": `${moment().unix()}`,
                  "status": "",
                  "password_attempts": 0
              },
              "customer": {
                  "account_id": "",
                  "loans": [],
                  "email": applicationData.account.email,
                  "name": applicationData.account.firstname,
                  "last_name": applicationData.account.lastName,
                  "phone_number": applicationData.account.phone,
          
                  "address_1": applicationData.address.address1,
                  "city": applicationData.address.city,
                  "province": applicationData.address.province,
                  "postal_code": applicationData.address.zip,
                  "country": applicationData.address.country,
          
                  "is_number_verified": false,
                  "is_id_verified": false,
          
                  "employer_name": applicationData.account.employerName,
                  "employment_industry": applicationData.account.employmentIndustry,
                  "employment_status": applicationData.account.employmentStatus,
                  "salary_paid_in_account": Number(applicationData.account.salary),
                  "bank_name": applicationData.account.bank,
          
                  "affordability": {
                      "net_income": Number(applicationData.affordability.netIncome),
                      "accomodation": Number(applicationData.affordability.accomodation),
                      "transport": Number(applicationData.affordability.transport),
                      "food": Number(applicationData.affordability.food),
                      "maintainence":  Number(applicationData.affordability.maintainence),
                      "education": Number(applicationData.affordability.education),
                      "medical": Number(applicationData.affordability.medical),
                      "water_electricity": Number(applicationData.affordability.waterElectricity),
                      "credit_card": Number(applicationData.affordability.creditCard),
                      "personal_loan": Number(applicationData.affordability.personalLoan)
                  }
              },
              "loan": {
                  "created_at": `${moment().unix()}`,
                  "id_number":  applicationData.account.username,
                  "amount": applicationData.loan.amount,
                  "term_in_days": applicationData.loan.days,
                  "interest_rate": applicationData.loan.interestRate,
                  "fees": applicationData.loan.fees,
                  "status": "", 
                  "purpose": "EMERGENCY EXPENSE",
                  "currency": "ZAR",
                  "loan_applicants_image_base64": applicationData.loan_applicants_image_base64,
                  "truid_collection_id": "",
          
                  "initiation_fee": applicationData.loan.initiationFee,
                  "interest_amount_per_day": applicationData.loan.interestPerDay,
                  "service_fee": applicationData.loan.serviceFee,
                  "total_repayment": applicationData.loan.totalRepayment
              },
          };
        }

      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
  }

  static async getTruIdTransactionHistory(token, loanId, isCheckout, onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/transaction-history/${loanId}`;
    var config = {
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-type': isCheckout ? 'checkout' : 'loan'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.response.data);
    });
  }

  static async getContract(token, loanId, onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/generate-contract/${loanId}`;

    var config = {
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.response);
    });
  }

  static async getAllArticles(onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/articles`;
    const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN;

    var config = {
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Basic ${adminToken}`,
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.response);
    });
  }

  static async likeArticle(id, onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/article/${id}?like=0`;
    const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN;

    var config = {
      method: 'put',
      url: url,
      headers: {
        'Authorization': `Basic ${adminToken}`,
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.response);
    });
  }

  static async readArticle(id, onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/article/${id}?read=0`;
    const adminToken = process.env.REACT_APP_ADMIN_EKASI_PAY_TOKEN;

    var config = {
      method: 'put',
      url: url,
      headers: {
        'Authorization': `Basic ${adminToken}`,
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.response);
    });
  }

  static async signContract(token, loanId, customerSignatureBase64 , onSuccess, onError) {
    const url = `${EKasiAPIClient.baseUrl}/sign-contract`;
    const body = {
      "loan_id": loanId,
      "customer_signature": customerSignatureBase64, 
    };

    var config = {
      method: 'post',
      data:  JSON.stringify(body),
      url: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(function (response) {
      onSuccess(response.data);
    })
    .catch(function (error) {
      onError(error.data);
    });
  }

  static async updateCustomer(token, customerData, onSuccess, onError) {
      const url = `${EKasiAPIClient.baseUrl}/update-customer`;
      const body = {
        "customer": {
          "account_id": "",
          "loans": [],
          "email": customerData.email,
          "name": customerData.firstname,
          "last_name": customerData.lastName,
          "phone_number": customerData.phone,
  
          "address_1": customerData.address1,
          "city": customerData.city,
          "province": customerData.province,
          "postal_code": customerData.zip,
          "country": customerData.country,
  
          "is_number_verified": false,
          "is_id_verified": false,
  
          "employer_name": customerData.employer_name,
          "employment_industry": customerData.employment_industry,
          "employment_status": customerData.employment_status,
          "salary_paid_in_account": customerData.salary_paid_in_account,
          "bank_name": customerData.bank_name,
  
          "affordability": {
              "net_income": customerData.affordability.net_income,
              "accomodation": customerData.affordability.accomodation,
              "transport": customerData.affordability.transport,
              "food": customerData.affordability.food,
              "education": customerData.affordability.education,
              "maintainence": customerData.affordability.maintainence,
              "medical": customerData.affordability.medical,
              "water_electricity": customerData.affordability.water_electricity,
              "credit_card": customerData.affordability.credit_card,
              "personal_loan":customerData.affordability.personal_loan
          }
      },
      }
      var config = {
        method: 'post',
        url: url,
        data:  JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      axios(config)
      .then(function (response) {
        onSuccess(response.data)
      })
      .catch(function (error) {
        onError(error.response.data)
      });
    }
}
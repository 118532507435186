import React, {useState} from 'react';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import Helmet from 'react-helmet';
import { Box } from '@mui/system';
import { Button, Container, ListItem, Typography, Slider, styled, Checkbox, FormControlLabel, Divider } from '@mui/material';
import { Info, ShoppingCartCheckout } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCheckMobile from '../../common/useCheckMobile';
import Globals from '../../common/Globals';
import EKasiDialog from '../../common/components/EKasiDialog';
import moment from 'moment';
import EKasiToolTip from '../../common/components/EKasiToolTip';
import EKasiAnimationLoader from '../../common/components/EKasiAnimationLoader';
import { useEffect } from 'react';
import EKasiAPIClient from '../../network/EkasiAPIClient';

const PrettoSlider = styled(Slider)({
    color: Colours.variantViridianGreen,
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: `2px solid ${Colours.maroon}`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

const CheckoutHome = ({ userData, setNavAppearance, setRedirect}) => {
    const navigate = useNavigate();
    const isMobile = useCheckMobile();
    const interestRate = Globals.iRate;
    const [repayments, setRepayments] = useState([0.00]);
    const [isLoading, setLoading] = useState(false);
    const [thirtyPercentDeclaration, setThirtyPercentDeclaration] = useState(false);
    const { id } = useParams()

    useEffect(() => {
        setNavAppearance(false);
        getCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useState({
        id,
        merchant_chkt_id: "",
        merchant_redirect_url: "",
        merchant_id: "",
        merchant_name: "",
        minLoan: Globals.minLoan,
        maxLoan: Globals.maxLoan,
        minLoanTerm: 1,
        maxLoanTerm: 3,
        maxInitiationPercentage: Globals.maxInitionPercentage,
        maxServiceFee: Globals.maxServiceFee,

        amount: Globals.minLoan,
        days: Globals.minCheckoutTerm,
        interestRate: interestRate,
        interestPerDay: 0.00,
        initiationFee: 0.00,
        totalRepayment: [0.00],
        serviceFee: 0.00,
        fees: 0.00,
    });

    const getCheckout = () => {
        setLoading(true);
        const token = userData?.token 
        EKasiAPIClient.getCheckout(token, id, (res) => {
            const data = res.data;
            const {
            merchant_chkt_id,
            merchant_redirect_url,
            merchant_id,
            merchant_name,
            amount,
            initiation_fee,
            interest_amount_per_day,
            service_fee,
            total_repayment,
            term_in_days,
            fees,
            interest_rate,
            } = data;

            const term = Number(term_in_days) / Globals.daysInAMonth;
            const newAmount = amount;
            const initFee = initiation_fee;
            const interestAmountPerDay =  interest_amount_per_day;
            setRepayments(total_repayment);
            setState({
                id,
                merchant_chkt_id: merchant_chkt_id,
                merchant_redirect_url: merchant_redirect_url,
                merchant_id: merchant_id,
                merchant_name: merchant_name,
                minLoan: Globals.minLoan,
                maxLoan: Globals.maxLoan,
                minLoanTerm: Globals.minCheckoutTerm,
                maxLoanTerm: Globals.maxCheckoutTerm,
                maxInitiationPercentage: 10,
                maxServiceFee: service_fee,
        
                amount: newAmount,
                days: term,
                interestRate: interest_rate,

                initiationFee: initFee,
                interestPerDay: interestAmountPerDay,
                fees:  fees,
                serviceFee: service_fee, // This is all the fees together
                totalRepayment: total_repayment,
            });
            setLoading(false);
        }, (error) => {
            showDialog(error.code, Strings.checkoutError, Strings.cancel, Strings.retry, "checkout_cancel", "checkout_retry");
            setLoading(false);
        });
    }

    const isThisMonthLoan = (timestamp) => {
        return moment(timestamp).isSame(moment(), 'year') &&  moment(timestamp).isSame(moment(), 'month')
    }

    // TODO: LOAN APP BLOCKING
    const hasLoansInPayment = () => {
        if (typeof userData !== "undefined" && userData !== null) {
            const loanInPayment = userData?.loans.find((loan) => {
                return isThisMonthLoan(loan.created_at) && (loan.status === "PAID_OUT" ||  loan.status === "PENDING_PAYMENT" || loan.status === "DECLINED");
            });

            return loanInPayment === undefined
        }

        return false;
    }

    const onNavigateToOnboarding = () => {
        if(hasLoansInPayment()) {
            showDialog(Strings.alreadyHaveALoan,Strings.youCannotApplyForTwoLoans, Strings.cancel, Strings.contactSupport, "", "");
            return
        } 
        navigate(`/checkout/${id}/onboard`, { state: { loan: {...state} } });
    }

    const onTermChange = (event, value) => {
        const newTerm = Number(value)
        const newRepayments = repayments.slice(0, value).map((value) => {
            const repayment = Number(((state.initiationFee / newTerm) + state.amount + state.serviceFee).toFixed(2));
            return repayment;
        })
        setState({
            ...state,
            days: newTerm,
            totalRepayment: newRepayments,
        });
    }

    const RepaymentColumn = ({amount, month}) => (
        <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 10 }}>
            <Typography style={{ fontSize: 13, color: Colours.viridianGreen, fontWeight: 'bold'}}  textAlign="center">R {amount.toFixed(2)}</Typography>
            <Typography style={{ fontSize: 10, color: Colours.viridianGreen, fontWeight: '100'}}  textAlign="center">{moment().add(month, 'month').format('MMMM')}</Typography>
        </Box>
    );

    const LoanCalculator = ({_state}) => (
        <Container style={{ display: 'flex', flexDirection: 'row',  backgroundColor: Colours.white, borderRadius: 15, padding: 0, marginBottom: 20}}>
            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: Colours.variantViridianGreen, borderRadius: 15}}>
                <ShoppingCartCheckout fontSize="large" style={{color: Colours.white, paddingTop: 15}} />
                <Typography style={{ paddingTop: 15, color: Colours.white, fontWeight: 'bold'}}  textAlign="center">{_state.merchant_name}</Typography>
                <Typography style={{paddingBottom: 15, color: Colours.white, fontWeight: '100'}}  textAlign="center">Checkout - {_state.merchant_chkt_id}</Typography>
                <ListItem key={1} sx={{ display: 'list-item', color: Colours.white, fontWeight: '200'  }}>
                    {Strings.easyToApply}
                </ListItem>
                <ListItem  key={2}  sx={{ display: 'list-item', color: Colours.white, fontWeight: '200' }}>
                    {Strings.getCheckout}
                </ListItem>
                <ListItem  key={3}  sx={{ display: 'list-item', color: Colours.white, fontWeight: '200' }}>
                    {Strings.repayInThreeMonthlyInstalments}
                </ListItem>
                {
                     _state.interestRate === 0 ?
                     <ListItem  key={4}  sx={{ display: 'list-item', color: Colours.white, fontWeight: '200' }}>
                         {Strings.pay30Deposit}
                     </ListItem> : null
                }
               
            </Container>
            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: Colours.white, borderRadius: 15, padding: 15}}>
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.checkoutAmount}</Typography>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.thisIsYourCheckoutAmount}</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 20, color: Colours.variantViridianGreen}}  textAlign="center">R {_state.amount.toFixed(2)}</Typography>

                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.repayIn}</Typography>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.adjustDaysSliderToChooseCheckoutTerm}</Typography>
                <Typography style={{ display: "inline", fontWeight: '100', fontSize: 15, color: Colours.variantViridianGreen}}  textAlign="center">
                    <Typography style={{ display: "inline", fontWeight: 'bold', fontSize: 20, color: Colours.variantViridianGreen}}  textAlign="center">
                    {`${_state.days.toString()} monthly instalment(s) `}
                    </Typography>
                </Typography>

                <PrettoSlider
                    name="Monthly instalment(s)"
                    defaultValue={_state.days}
                    valueLabelDisplay="auto"
                    onChangeCommitted={onTermChange}  
                    step={1} 
                    min={Globals.minCheckoutTerm} 
                    max={Globals.maxCheckoutTerm} />
                <Box style={{display: 'flex', flexDirection: 'row'}}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 12, color: Colours.viridianGreen}}  textAlign="center">{Strings.totalFees}</Typography>
                    <EKasiToolTip arrow={true}
                        title={
                            <React.Fragment>
                            <Typography  style={{ fontWeight: '100', fontSize: 10}} color="inherit">
                                <b>
                                    <u>
                                        {Strings.feesBreakdown}
                                    </u>
                                </b>
                                <li>
                                    {`${Strings.initiationFeeR}${Number(_state.initiationFee).toFixed(2)}`}
                                </li>
                                <li>
                                    {`${Strings.serviceFeeR}${Number(_state.maxServiceFee).toFixed(2)}`}
                                </li>
                                <li>
                                    {`${Strings.interestAmountR}${Number(_state.serviceFee === 0 ? 0.00 : _state.serviceFee - Globals.maxServiceFee - _state.initiationFee).toFixed(2)}`}
                                </li>
                                {`${Strings.interestRatePerc} ${Number(_state.interestRate).toFixed(2)}% ${Strings.perMonth}`}
                            </Typography>
                    </React.Fragment>
                  }>
                    <Info color="primary" fontSize='small'/>
                    </EKasiToolTip>
                </Box>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">R {_state.serviceFee.toFixed(2)}</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.totalRepayment}</Typography>
                <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    {
                        _state.interestRate === 0 ?
                        <>
                         <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 10 }}>
                            <Typography style={{ fontSize: 13, color: Colours.viridianGreen, fontWeight: 'bold'}}  textAlign="center">R {(_state.amount * Globals.layByDepositPercent / 100.00).toFixed(2)}</Typography>
                            <Typography style={{ fontSize: 10, color: Colours.viridianGreen, fontWeight: '100'}}  textAlign="center">{Strings.thirtyPercentDeposit}</Typography>
                        </Box>
                        <Divider orientation='vertical'/>
                        </> : null
                    }
                    {
                        _state.totalRepayment.map((amount, index) => (
                            <>
                            <RepaymentColumn key={`repayments-${index}`} amount={amount} month={index + 1}/>
                            {
                                (((index + 1) % _state.days) > 0) ? 
                                <Divider orientation='vertical'/> : null
                            }
                            </>
                        ))
                    }
                </Box>
                {
                    _state.interest_rate === 0 ?
                    <FormControlLabel style={{padding: 10, color: Colours.viridianGreen, fontWeight: '100'}}  required control={
                        <Checkbox onChange={(e) => setThirtyPercentDeclaration(e.target.checked)} />            
                        } label={Strings.thirtyPercentDeclaration} />
                    : null
                }
                <Button 
                    disabled={_state.interest_rate === 0 && !thirtyPercentDeclaration} 
                    onClick={onNavigateToOnboarding}
                    style={{ maxWidth: "40%", backgroundColor: Colours.variantViridianGreen, margin: 15, borderRadius: isMobile ? 13 : 28 }}
                    fullWidth 
                    disableElevation 
                    variant="contained"
                >
                    {Strings.applyNow}
                </Button>
                <Button
                    component={Link}
                    to={_state.merchant_redirect_url}
                    style={{ maxWidth: "40%", backgroundColor: Colours.variantViridianGreen, margin: 15, borderRadius: isMobile ? 13 : 28 }}
                    fullWidth 
                    disableElevation 
                    variant="contained"
                >
                    {Strings.goBackTo}{_state.merchant_name}
                </Button>
            </Container>
        </Container>
    );

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [negativeHandler, setNegativeHandler] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [positiveHandler, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    
    const negativeAction = (e) => {
        if(negativeHandler === "checkout_cancel") {
            setNavAppearance(true)
            navigate("/application");
            return
        }
    }

    const positiveAction = (e) => {
        if(positiveHandler === "checkout_retry") {
            getCheckout();
            setPositiveHandler("")
            return
        }

       navigate('/support');
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
      }

    return (
        <>
            <Helmet>
                <title>{Strings.instantCheckout}</title>
            </Helmet>
            {isLoading ? <EKasiAnimationLoader /> : null}
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeAction} handlePositiveResponseButton={positiveAction} />
            <Box height="100vh" display="flex" flexDirection="column">
                <LoanCalculator _state={state}/>
            </Box>
        </>
    )
}

export default CheckoutHome;
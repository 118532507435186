import Globals from "../common/Globals";

/* eslint-disable no-multi-str */
 const LocalizedStrings = {
    submittingQuestions: "Submitting security questions",
    myFinancialWellbeing: "Financial wellness center",
    ekasiPaySolutions: "eKasi Pay Solutions",
    allowCustomersPurchase: "Allow your customers to purchase online or in-store with flexible  interest-free or affordable payment plans.",
    integrationText: "More Like plug and play, It's as easy as a click of a button, an API call with your checkout identifier and we handle the rest.",
    integrationRisk: "We take the risk",
    easyIntegration: "Easy Integration",
    integrationRiskText: "Once customer has onboarded through us we pay you for the Product or Service the customer has purchased and take care of the rest.",
    truidRedirectError: "Something went wrong whilst trying to redirect you to Truid, please try again",
    sendQuery: "Send query",
    hi: "Hi",
    helpSupport: "Help support",
    ekasiLoans: "eKasi Pay Credit",
    learnMore: "Learn more",
    proceedToSign: "Proceed to signing",
    verifyingIdNumber: "Verifying ID number.",
    alreadyHaveALoan: "Already have a loan",
    loanCalculator: "Loan calculator error",
    fetchFailed: "Oops, we could not fetch data from the server, if this persists please contact support.",
    youCannotApplyForTwoLoans: "You have already made an application this month, please contact support for help.",
    login: "Login",
    days: "days",
    loansCalc: "Loans calculator",
    home: "Home | Credit Checkout API | Loans",
    instantCheckout: "Instant checkout",
    getCreditToday: "Get a loan today!",
    easyToApply: "Quick and easy to apply online - it is 100% self-service.​",
    getCashToBank: "Get your cash in your bank account in 2 hours.",
    getCheckout: "Get your checkout in no time.",
    repayInThreeMonthlyInstalments: "Repay your checkout in 3 monthly instalments.",
    instalments: "Instalments",
    pay30Deposit: "Pay 30% deposit on your checkout Lay-by",
    repayLoan: "Repay your loan in 1 monthly instalment.",
    monthlyInstalments: "monthly instalments",
    howMuchCash: "How much do you need?",
    checkoutAmount: "Checkout amount",
    adjustCashSlider: "Adjust slider to choose loan amount.",
    thisIsYourCheckoutAmount: "This is the amount your checkout is.",
    applyNow: "Apply now",
    applyForCredit: "Apply for credit",
    goBackTo: "Back to ",
    repayIn: "Repay in",
    term: "Term",
    adjustDaysSlider: "Adjust slider to choose the loan term.",
    adjustDaysSliderToChooseCheckoutTerm: "Adjust slider to choose the checkout instalments.",
    ncrAccredited: "NCR accredited",
    ekasiIsAccreditedRegistered: `eKasi Pay Credit. Registration Number 2022/590090/07.`,
    ekasiIsAccredited: `An Authorised Financial Services and Credit Provider (${Globals.ncrNumber}).`,
    ekasiDomicile: "Contact support - 064 590 7768",
    ekasiNeedEmail: "Email support - help@ekasicredit.co.za",
    followUsOn: "Follow us on: ",
    totalFees: "Total fees and interest",
    creditAgreement: "Credit agreement",
    applicationDate: "Application date",
    totalRepayment: "Total repayment",
    creditStatus: "Credit status",
    contact: "Contact support",
    aboutUs: "About us",
    FAQs: "FAQs",
    readInfo: "Please read for more info:",
    feesBreakdown: "Fees & Interest breakdown:",
    initiationFeeR: 'Initiation fee: R ',
    serviceFeeR: 'Service fee: R ',
    interestAmountR: 'Interest amount: R ',
    interestRatePerc: '@Interest rate: ',
    perMonth: 'per month',
    termsOfUse: "Terms of use",
    privacyPolicy: "Privacy Policy",
    eKasiCredit: "eKasi Pay Credit",
    checkoutCreditAmount: "Checkout Credit Amount",
    getStarted: "Get started",
    affordabilityAssesment: "Affordability Assessment",
    completeFieldBelow: "Please complete the fields below.",
    username: "Username",
    password: "Password",
    usernameHelper: "Your username is your ID number",
    bankStatementHelper: "Upload your bank statement.",
    passwordHelper: "Your password must be 8 characters.",
    done: "Done",
    next: "Next",
    country: "Country",
    province: "Province",
    firstname: "First name",
    lastname: "Last name",
    address: "Address",
    email: "Email",
    city: "City",
    postalCode: "Postal code",
    back: "Back",
    submitLater: "I will do it later",
    submitDocuments: "Submit documents",
    salaryGrossIncome: "Salary/Income",
    netIncome: "Net income",
    netIncomeHelper: "Monthly income after deductions",
    salaryHelper: "Your monthly income paid to your bank account.",
    bankSalary: "Bank where salary is paid",
    employerName: "Employer name",
    employmentStatus: "Employment status",
    employmentIndustry: "Employment industry",
    salaryConfirmation:"I confirm that I have received at least 3 months salary/income.",
    debtReviewConfirmation:"I confirm that I am not under debt review.",
    tcConfirmation:"I accept the Terms of use and Privacy Policy.",
    termsAndConditions: "Terms & Conditions",
    consentCredit: "Consent to Perform Credit Bureau Enquiry",
    iAccept: "I accept the ",
    accomodationExpenses: "Accommodation expense",
    transportExpenses: "Transport expense",
    foodExpenses: "Food expense",
    educationExpenses: "Education expense",
    medicalExpenses: "Medical expense",
    waterElectricity: "Water and electricity",
    maintainence: "Maintenance expense",
    personalLoan: "Personal loan repayment",
    creditCard: "Credit card repayment",
    personalLoansHelp: "Total monthly personal Loan repayment",
    creditCardDebtHelp: "Total monthly credit card repayment",
    declaration:"I confirm that all the data supplied is correct.",
    creditCheck:"I hereby provide consent and authorise Ekasi Pay Credit to perform a credit check through Experian South Africa.",
    thirtyPercentDeclaration:"I atleast have 30% deposit for this checkout.",
    reviewApplication: "Review application",
    weSentVerificationCode: "We have sent a verification code to %1. Please enter the code below:",
    clear: "Clear",
    resend: "Resend",
    resendIn: "Resend in ",
    personalInformation: "Personal information",
    contactInformation: "Contact information",
    employmentInformation: "Employment information",
    financialInformation: "Financial information",
    loanInformation: "Loan information",
    checkoutInformation: "Checkout information",
    estimated: "*estimated",
    processApplication: "Process application",
    idNumber: "Identity number",
    phoneNumberEnter: "Enter your phone number",
    phoneNumber: "Phone number",
    creditAmount: "Loan amount",
    creditTerm: "Loan term",
    verifyingInfo: "Verifying information, please be patient, this might take a while.",
    retrievingCustomerData: "Retrieving customer data.",
    initializingLoanContract: "Initializing loan contract.",
    creditInitiation: "Loan initiation fee",
    creditServiceFee: "Loan service fee",
    loanApprovedPending: "Congratulations we can offer you the amount you requested, now let us upload your bank statement so that we can verify your income and pay you your funds.",
    checkoutApprovedPending: "Congratulations, we can pay for your checkout, now let us upload your bank statement so that we can verify your income and you'll have your checkout shipped in no time.",
    documentUploadEmail: "help@ekasicredit.co.za",
    uploadStatement: "Upload bank statement(*.pdf, *.png, *.jpg)",
    uploadID: "Upload ID(*.pdf, *.png, *.jpg)",
    uploadAlternativelyEmail: "Alternatively you can email your latest bank statement and your Identity Card/Document to %. Please kindly note that uploading manaully or via email may result in a delay in your application. Note: make sure you quote the Loan ID - % in the subject",
    approved: "We have retrieved all your documents, give us a few minutes to verify all collected information. Once everything has been verified you will recieve a debit check sms, please approve it. Then login to sign your loan contract and you will recieve your credit in no time! ",
    account: "Account",
    documentUpload: "Manaully upload your bank statement",
    accountAlreadyExists: "An account already exists with this Identity number, please login.",
    somethingWentWrongUploading: "Oops, something went wrong whilst trying to upload your data, if this persists please email your documents to help@ekasicredit.com",
    ok: "Ok",
    goToSignIn: "Go to sign in",
    querySuccessful: "Your query was successfully sent, our team will get back to you in the next few minutes.",
    queryFailed: "Your query was unsuccessful, please try again or directly call our support center.",
    pleaseSelectQuery: "Please select your query",
    supportValidationError: "Please make sure all required(*) fields have been completed correctly.",
    unknownError: "Unknown error",
    applicationError: "Application error",
    somethingWentWrongSendingApplication: "Oops, something went wrong whilst trying to send your application, please try again.",
    forgottenPassword: "Forgot password ?",
    myDashboard: "My dashboard",
    logout: "Logout",
    loginValidation: "Login validation error",
    loginEitherIdOrPrivacy: "Please make sure that you have accepted the privacy policy and that you have the correct Identity number.",
    loginFailed: "Login failed",
    loginFailureMessage: "Something went wrong whilst trying to login, please check your data.",
    myLoans: "My credit",
    loanContract: "Loan contracts",
    signHere: "Please sign in this box:",
    signature: "Signature",
    documentSigning: "Your loan agreement was successfully signed, please give us a few minutes to pay your funds.",
    pleaseSign: "Please sign in the box provided.",
    noDocumentToSign: "There is no document to sign. Please click `loans` then reclick on `loan contracts` to reload the page.",
    myFinancials: "Financial details",
    myAddress: "Address details",
    myPersonalInfo: "Personal information",
    update: "Update",
    cancel: "Cancel",
    retry: "Retry",
    submitAnswers: "Submit answers",
    uploadUsingTrueId: "Verify your income using TruID",
    or: "Or",
    capturePhoto: "Capture photo",
    captureError: "Photo capture error",
    identityVerification: "Identity verification",
    somethingWentWrongMostLikely: "Oops, Something went wrong, this was most likely a ",
    idUploadFailure: "Make sure that you have uploaded your Identity card/document before capturing the photo.",
    infoUpdate: "Information update",
    customerDataUpdateSuccess: "The data was updated successfully.",
    truIDService: "TruID Service",
    truIDServiceNotAvailable: "Oops, sometthing went wrong whilst trying to reach the TruID servers, most likely because %1, please try again.",
    weCantGiveYouAmount: "Your financial wellbeing is important to us, from the data you've supplied we can't afford you this loan. Please contact us for a more information.",
    accept: "Accept",
    decline: "Decline",
    newOffer: "New offer",
    errorAutoAssess: "An error has occured whilst trying to auto assess your application, please contact support.",
    contactSupport: "Contact support",
    pageNotFound: "Oops, sorry we could not find the page you're looking for.",
    weWouldLikeToOfferYou: "Congratulations, we would like to offer you %1, so your repayment has been changed to %2.",
    truidIncompleteError: "Oops, we could not complete your income verification through TruID, if the problem persists please upload the bank statement using the submit button or via email.",

    // Privacy policy
    agreement: "1. AGREEMENT",
    agreementBody: "<p>The privacy policy(the Policy)  informs you of the different information <b>eKasi Pay Credit</b>, Inc. (<b>we</b> or <b>us</b> or <b>“eKasi Pay Credit”</b> or <b>“eKasi Pay Credit”</b>) collects as well as our policies and practices regarding the collection, use and disclosure of that information through the web pages at <href>www.ekasicredit.co.za/</href> (the <b>“Website”</b>, the <b>\"Company\"</b>). For application of these Terms please note that <b>eKasi Pay Credit</b> is private company operating in South Africa.\
    </p>\
    <p>\
    Please read meticulously through this Policy, because by using the website, you are acknowledge that you understand and agree to the terms of this Policy, and consent to the types of information and the manner in which we may collect, use and disclose such information. If you do not agree to the terms of this Policy, please do not use the website.\
    </p>\
    <p>\
    We reserve the right to change the provisions of this Policy at any time. We will alert you that changes have been made by indicating on the Policy and date it was updated. We encourage you to review this Policy from time to time to make sure you understand how any information you provide will be used. Each time you access the Website the most recent version of the Policy will apply. The continued use of the Website following the posting of changes to these terms will mean you agree to these changes.\
    <p>\
    \
    <p>\
    We will make sure that all Personal Information(as defined below) that we collect and maintain will be:\
    </p>\
    <ul style=“list-style-type:square”>\
 <li>Processed lawfully, fairly, and in a transparent manner;</li>\
 <li>Collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes;</li>\
 <li>Adequate, relevant, and limited to what is necessary;</li>\
<li>Accurate and kept up to date; and</li>\
<li>Processed in a manner that ensures appropriate security of the Personal Information,  including protection against unauthorised or lawful processing and against accidental loss, destruction or damage.</li>  </ul>",
    informationWeCollect: "2. INFORMATION WE COLLECT:",
    informationWeCollectBody: "\
    <p style=\"text-decoration: underline;\">2.1 Personal Information You Provide to Us<p>\
\
<p>\
When you register or apply for a loan for the first time and access or use the Website, we collect personal information, including name, email address, identity  number, phone number and other demographic information (or any other questions and answers that you answer in good faith to use this service) and any other information that you may provide while accessing or using our third party application programming interfaces. All collectively referred to as personal information.\
<p>\
\
<p>\
We only have access to and collect Personal information that you voluntarily give us by filling out our forms, surveys and answering questions which are part of the platform, or via email or other direct contact from you via social media or Website interaction.\
</p>\
\
<p>\
If you do not want this information to be shared with our employees, marketing partners, service providers and subcontractors, upon your request, we will not share your information. However, your ability to access and use the Website, and request services, may be limited or interrupted.\
</p>\
\
<p style=\"text-decoration: underline;\">2.2. Personal Information Collected by Automated methods<p>\
\
<p>\
Whenever you use the Website, we and/or our service providers may use a variety of technologies that automatically collect information about how the Website is accessed and used (<b>“Usage Information”</b>). Usage Information may include, in part, browser type, operating system, the page viewed, the time, how many users visited the Website, and the Website you visited immediately before the Website. This statistical data provides us with information about the use of the Website, such as how many visitors visit a specific page on the Website, how long they stay on that page, which websites they are coming from and which hyperlinks, if any, they “click” on, which part of the Platform are used most, by whom and how.\
</p>\
\
<p>\
Usage Information also includes error messages and crash analytics. Usage Information helps us to keep the Site user friendly and to provide visitors with readily accessible and helpful information. We may also use your Usage Information to troubleshoot issues with access or use of our Website. Usage Information is generally non-identifying, but if we associate it with you as a specific and identifiable person, we treat it as Personal Information.\
</p>\
\
<p>\
We believe that such technology usage is fair, lawful, and proportional to the legitimate interest and needs of our business, and that our methodology fairly addresses each user’s legitimate rights and expectations in view of the context and purpose for the collection and use of the information collected.\
</p>\
\
<p>\
The personal information provided in the website, including financial information, credit worthiness calculations are all stored by us, and can be accessed by members of our call centre only in order to assist you in the use, maintenance or operation of the Website.\
</p>",
    howWeUseYourInfo: "3. HOW WE USE YOUR INFORMATION?",
    howWeUseYourInfoBody: "\
    <p>\
We will use your information to respond to you regarding the reason you contacted us. We will also use your information as follows:\
</p>\
<ul style=\"list-style-type:square\">\
<li><b>Create a new loan application:</b> A user may fill in an online form in order to gain access to credit or further use the Website.  During registration the user enters their personal information, financial information and gives us consent to doing credit worthiness checks with our service providers. Contact details are used to communicate with the users of any changes/update to any contractual agreements him/her has with <b>eKasi Pay Credit</b>.</li>\
<li><b>Credit Model Optimisation</b>: The users financial information be it bank statements and credit reports are used to optimise <b>eKasi Pay Credit's Credit Risk Model</b>. The Model only uses financial information and runs calculations on financial and demographic data which will never be tied back to the user all in efforts to improve the Credit Risk Model. </li>\
<li><b>Customer Service</b>: Once you have applied for a loan, we may contact you via phone call, sms, email to inform you of any changes or updates to our Policy, to support you, or to inform you of other products you may qualify for.</li>\
  <li><b>Sales and Marketing</b>:  if you send a support query through our Website, email , social media platforms, or request to be called or contacted, we or our marketing partners may contact you via text, email or phone call.</li>\
</ul>\
\
<p>\
If at any time you wish that we cease communication with you, please notify us using the contact information provided below in the <b>“Contact Us”</b> section.\
</p>",
    doWeShareYourInfo: "4. DO WE SHARE YOUR INFORMATION?",
    doWeShareYourInfoBody: "\
    <p>\
Except as provided herein, we will not trade, rent, share or sell you Personal Information to third parties. Please note that your information may be shared with our marketing partners to contact you for marketing purposes. If you do not want this information to be shared with our marketing partners, upon your request, we will not share your information.\
</p>\
\
<p>\
Except as provided herein, unless provided consent to do so through our loan application form we will not share your Loan Repayment Information with any of our <b>Credit Bureaus</b> hence you will not be allowed to make any applications without this consent. Only <b>employees</b>, service providers and subcontractors who need the Personal Information to perform a specific job are granted access to Personal Information. The computers.servers in which we store Personal Information are kept in a secure environment.\
</p>",
    accessAndControlOverInformation: "5. YOUR ACCESS TO AND CONTROL OVER YOUR INFORMATION",
    accessAndControlOverInformationBody: "\
    <p>\
You may do the following at any time by contacting us via the email address or phone number provided on our Website: \
</p>\
<ul style=\"list-style-type:square\">\
<li>See what we have about you; if any.</li>\
<li>Change or correct any contact information we have about you.</li>\
<li>Have us delete any data we have about you.</li>\
</ul>\
<p>\
We do not charge for these services. Your request will be processed within 30 days of the date on which we receive it.\
</p>",
    stepsToAccess: "6. WHAT STEPS DO WE TAKE TO PROTECT YOUR INFORMATION?",
    stepsToAccessBody: "\
    <p>\
We take considerable measures designed to protect your Personal Information in an effort to avoid loss, misuse and unauthorised access, disclosure, alterations, and destruction. We provide physical, electronic and procedural safeguards to protect Personal Information we process and maintain.\
</p>\
\
<p>\
As we all have come to the realization, no security measure is impenetrable. To protect the integrity of your information you must keep \
Your password confidential and not disclose it to any other person. You are responsible for all uses of the Website by any person using your password. Please advise us immediately if you believe your password has been compromised.\
</p>\
\
<p>\
If you find anything insecure about our Website, please do inform us we promise to buy you <b>coffee</b> and <b>donuts</b>.\
</p>",
    miscellaneousPP: "7. MISCELLANEOUS",
    miscellaneousPPBody: "\
    <p>\
This Policy and the privacy practices of <b>eKasi Pay Credit</b> are subject to exclusively to the laws of <b>South Africa(POPIA)</b>. Please note that <b>eKasi Pay Credit</b> servers are located in South Africa. <b>eKasi Pay Credit</b> makes no representation that this Policy and out practice comply with the laws of any other country or jurisdiction.\
</p>",
    contactUsPP: "8. CONTACT US",
    contactUsPPBody: "\
    <p>\
If you have questions about this Policy please contact via email at <href>help@ekasicredit.co.za</href>. To exercise any of your rights in this Privacy Policy please contact us in writing via email as indicated above. So that we may consider your request applicable by law. Please be aware that your request is subject to the following information received in full: \
</p>\
<ul style=\"list-style-type:square\">\
 <li>The name, phone number, email address, or any other identifier that you have used in this Website.</li>\
<li>Province and Country in which you are located.</li>\
<li>Clear description of information or content you wish retrieved or deleted or amended. </li></ul>",

    aboutUsBody: "\
    <p>\
We are <b>eKasi Pay Credit</b>, we are leading <b>South Africa</b> from the front with our innovative technologies which help you get credit in no time.\
</p>\
\
<p>\
Whether you are buying that product you have always wanted or paying for those unforeseen expenses, we will help you.\
</p>\
\
<p>\
We make sure our customers receive their money in no time. Our fully <b>automated</b> credit application process is safe and secure. Our systems are well equipped to give you a decision of affordability in less than minutes. We are always trying to improve your experience and make it easier for you to apply for credit.\
</p>\
\
<p>\
We are always keen on hearing about your experience so do please leave us feedback of your experience.\
<p>\
\
<p>\
We are building a platform that you can trust and that is within the bounds of <em>NCR regulations</em>.\
",
 intellectaulProperty: "2. Intellectual property",

 intellectaulPropertyBody: "\
 <p>\
All copyright and other intellectual property rights subsisting in this website and its contents, including without limitation to all text, images, graphics and code contained in this website, as well as its look and feel (collectively, the “Contents”) are owned by <b>eKasi Pay Credit</b>, or by <b>third-party providers</b>.\
</p>\
\
<p>\
You are not allowed to copy and/or print any information for any reason whatsoever and any request for information must be done through the appropriate channel on this website.\
<p>\
\
<p>\
The Company reserves the right, but has no obligation, to change the Contents at any time. Other than as specified above, neither this website nor any of its Contents may be modified or copied in whole or part in any form, including by framing, incorporation into other websites or other publication, or be used to create any derivative work.\
</p> \
\
<p>\
 No links to this website may be included in any other website without the Company's prior written permission. \
</p>\
\
<p>\
None of the Company's, nor any of its group companies' or third parties, trademarks, logos and/or the like may be used without the Company’s prior written permission.\
</p>\
\
<p>\
The Company reserves all its rights in such trademarks, logos and/or the like. \
</p>\
\
<p>\
Other than as specified above, nothing on this website should be construed as granting any right, license, permission or promise.\
</p>\
\
<p>\
The Company does not guarantee that you have any right to use content available on this website that is owned by any third party, and that third party’s permission will not be required before you use such content.\
</p>\
\
<p>\
None of the Contents may be changed, nor may any copyright or author attribution notice appearing on any of the Contents be altered or removed, without the Company’s prior permission.\
</p>\
 ",
 creditConsentTitle: "3. Consent to Perform Credit Bureau Enquiry",
 creditConsent: "\
 <p>\
Through the use of our website to make a <b>credit application</b>, you hereby provide consent and authorise <b>Experian South Africa</b> to perform the following actions in relation to <b>eKasi Pay Credit</b>:\
</p>\
<p>\
<li>To contact, request and obtain credit information (for the avoidance of doubt this includes any and all information held on the users’ profile by <b>Experian South Africa (Pty) Ltd (“Experian”)</b> including payment profile information) from <b>Experian</b> (a registered credit bureau) to verify the identity, perform an assessment of the business’ behaviour, profile, payment patterns, indebtedness, whereabouts, and creditworthiness or another related purposes in any format (including in pdf, xml or raw data string) available from Experian or any other third party data source and to continuously access the Business’ information from Experian for as long as I may have a relationship with Experian;</li>\
<li>make any reasonable enquiries to verify and research any details provided by me to Experian, including in the event of the Business settling invoices by debit order, to verify the Business’ bank account number with any other third-party service provider.</li>\
<li>to submit the Business information, including payment profile and default information and any other relevant information, to <b>Experian</b> or any other credit bureau and to allow the credit bureau to release the information for lawful purposes to third parties.</li>\
</p>\
 ",
 checkoutAPIDetails: "\
 <p>\
<b><em>Buy now! Pay later.</em></b><br/>\
Allow your customers to checkout their items on credit.\
</p>\
<p>\
<b>What your customers gets:</b>\
 <ol>Access to competitive interest rates.</ol>\
 <ol>A seamless credit application process.</ol>\
 <ol>Instant decision</ol>\
 <ol>A maximum of 3 months to pay.</ol>\
</p>\
<p>\
<b>What your business gets:</b>\
<ol>Free 1 month trial!</ol>\
<ol>Increase sales conversion rate.</ol>\
<ol>Easy integration process.</ol>\
<ol>Fast checkout payouts.</ol>\
<ol>Tailor made payment plan.</ol>\
<ol>RESTful API or onboard customers using the web platform</ol></p>",
layByAPIDetails: "\
<p>\
<b><em>Buy now! Pay in instalments.</em></b><br/>\
Allow your customers to checkout their items on and pay monthly instalments.\
</p>\
<p>\
<b>What your customers gets:</b>\
<ol>A seamless application process.</ol>\
<ol>Flexible payment method.</ol>\
<ol>You get a maximum of 3 months to pay.</ol>\
<ol>Customer must at least pay 30% deposit on the checkout.</ol>\
</p>\
<p>\
<b>What your business gets:</b>\
<ol>Free 1 month trial!</ol>\
<ol>Increase sales conversion rate.</ol>\
<ol>Easy integration process.</ol>\
<ol>Fast checkout payouts.</ol>\
<ol>Tailor made payment plan.</ol>\
<ol>RESTful API or onboard customers using the web platform</ol>\
</p>",
 services: "B2B Services",
 thirtyPercentDeposit: "30% deposit(due now)",
 getInTouch: "Get eKasi Payment Solutions",
 layByAPI: "Layby API",
 checkoutError: "Oops, something went wrong we could not find the checkout.",
 ourSolutions: "Our Solutions",
 checkoutAPI: "Credit Checkout API",
 verification: "Verification",
 verificationIncorrect: "Oops, the verification code you entered is incorrect, please try again.",
 newApplication: "New Application",
 affordability: "Affordability",
 review: "Review",
 incomeVerification: "Income verification",
 approvedTitle: "Approved",
 OTP: "OTP",
 vatFees: "Fees(incl. 15% VAT) and interest based on Chpt. 5 regulations of NCA, no. 34 of 2005"
};

export default LocalizedStrings;
import React, { useState } from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import LocalizedStrings from "../../localization/Strings";
import { Button, Container, InputLabel, MenuItem, Select, TextareaAutosize, Typography } from "@mui/material";
import EKasiLoader from "../../common/components/EKasiLoader";
import EKasiDialog from "../../common/components/EKasiDialog";
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from "../../common/FormInput";
import PhoneInput from "react-phone-number-input";
import { createRef } from "react";
import PhoneInputField from "../../common/PhoneInputField";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import emailjs from '@emailjs/browser';

export const Support = () => {
    const methods = useForm();
    const ref = createRef();

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isValidPhone, setValidPhone] = useState(false);
    const [phone, setPhone] = useState("");
    const [query, setQuery] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [queryText, setQueryText] = useState("");
    const _queries = [{id: 'FP', label: 'Forgotten Password'}, {id: 'B2BCHKT', label: 'B2B Checkout API'}, {id: 'B2BLB', label: 'B2B Lay-Buy API'}, {id: 'PMT', label: 'Payments'}, {id: 'LNS', label: 'Loan'},  {id: 'HLP', label: 'General Help'}, ];

    const isValidEmail = (email) => {
        if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
          return true
        }
        
        return false
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
      }

    return (
        <>
            <EKasiLoader open={isLoading}/>
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
            <Helmet>
                <title> Help | Support </title>
                <meta name="description" content="Contact us to apply for a loan, using our support center" />
            </Helmet>
            <Box style={{ display:  'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
                <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                    {`${LocalizedStrings.eKasiCredit} | `}
                    <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                        {LocalizedStrings.helpSupport}            
                    </Typography> 
                </Typography>
                <Container>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit((data) => {
                            if(isValidEmail(email) && query !== "" && isValidPhone){
                                var params = {
                                    "reply_to": email,
                                    "from_name": `${firstName} ${lastName}`,
                                    "to_name": `EKASI PAY CUSTOMER SERVICES`,
                                    "from_address": `${phone}`,
                                    "query": query,
                                    "message": `${queryText}`,
                                }
                                setLoading(true);
                                emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, params)
                                .then(function(response) {
                                    setLoading(false);
                                    showDialog(LocalizedStrings.helpSupport,LocalizedStrings.querySuccessful, "", LocalizedStrings.ok, null, null);
                                    setEmail("")
                                    setFirstName("")
                                    setLastName("")
                                    setQueryText("")
                                    setQuery("")
                                    setPhone("");
                                }, function(error) {
                                    setLoading(false);
                                    showDialog(LocalizedStrings.helpSupport,`${LocalizedStrings.queryFailed} - Error: ${error.status}`, "", LocalizedStrings.ok, null, null);
                                });
                            } else {
                                showDialog(LocalizedStrings.helpSupport, LocalizedStrings.supportValidationError, "", LocalizedStrings.ok, null, null);
                            }
                        })}>
                                    <FormInput value={firstName} onChange={(event) => setFirstName(event.target.value)} required name="first_name" label={LocalizedStrings.firstname}/>
                                    <FormInput value={lastName} onChange={(event) => setLastName(event.target.value)}  required name="last_name" label={LocalizedStrings.lastname}/>
                                    <FormInput value={email} onChange={(event) => setEmail(event.target.value)} required name='email' label={LocalizedStrings.email} />
                                    <PhoneInput
                                        ref={ref}
                                        inputComponent={PhoneInputField}
                                        error={!isValidPhone}
                                        defaultCountry="ZA"
                                        value={phone}
                                        onChange={async (inputNumber)  => {
                                        try {
                                            const phoneNumber = await parsePhoneNumber(inputNumber);
                                            const isValid = await isValidPhoneNumber(phoneNumber.nationalNumber, 'ZA') === true;
                                            setValidPhone(isValid)
                                            setPhone(inputNumber)
                    
                                        } catch(error) {
                                            setValidPhone(false)
                                        }
                                        }}
                                    />
                                    <InputLabel>{LocalizedStrings.pleaseSelectQuery}</InputLabel>
                                    <Select required value={query} fullWidth onChange={(e) => {
                                        setQuery(e.target.value);
                                    }}>
                                        {_queries.map((query) => (
                                                <MenuItem key={query.id} value={query.label}>
                                                    {query.label}
                                                </MenuItem>
                                        ))}
                                    </Select>
                                    <TextareaAutosize
                                       value={queryText}
                                       onChange={(event) => setQueryText(event.target.value)} 
                                       name="query_text"
                                       aria-label="minimum height"
                                       minRows={20}
                                       placeholder="How can we help you?"
                                       style={{ marginTop: 10, width: '100%', borderColor: Colours.backgroundGrey, fontSize: 15 }}
                                    />
                                    <Button type="submit" style={{ margin: 10, backgroundColor: Colours.variantViridianGreen, padding: 10}} fullWidth  disableElevation variant="contained">{LocalizedStrings.sendQuery}</Button>

                        </form>
                    </FormProvider>
                </Container>
            </Box>
        </>
    )
}

export default Support;
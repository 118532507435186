import React from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import { Typography } from "@mui/material";
import Localized from '../../localization/Strings';


export const SubheadingWithBody = ({heading, body}) => (
    <Typography sx={{ textAlign: 'left', p: 5, typography: 'h6', color: Colours.viridianGreen, fontWeight: 'bold' }}>
            {heading}
            <Typography sx={{ typography: 'body', color: Colours.black, fontWeight: '300' }}>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>           
            </Typography> 
    </Typography>
);

export const Privacy = () => {
    return (
        <>
        <Helmet>
            <title>Privacy Policy</title>
        </Helmet>

        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
            <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${Localized.eKasiCredit} | `}
                <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                    {Localized.privacyPolicy}            
                </Typography> 
            </Typography>
            <SubheadingWithBody heading={Localized.agreement} body={Localized.agreementBody} />
            <SubheadingWithBody heading={Localized.informationWeCollect} body={Localized.informationWeCollectBody} />
            <SubheadingWithBody heading={Localized.howWeUseYourInfo} body={Localized.howWeUseYourInfoBody} />
            <SubheadingWithBody heading={Localized.doWeShareYourInfo} body={Localized.doWeShareYourInfoBody} />
            <SubheadingWithBody heading={Localized.accessAndControlOverInformation} body={Localized.accessAndControlOverInformationBody} />
            <SubheadingWithBody heading={Localized.stepsToAccess} body={Localized.stepsToAccessBody} />
            <SubheadingWithBody heading={Localized.miscellaneousPP} body={Localized.miscellaneousPPBody} />
            <SubheadingWithBody heading={Localized.contactUsPP} body={Localized.contactUsPPBody} />
        </Box>
        
        </>
    )
}

export default Privacy;
import React from 'react';
import { TextField, Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

const FormInput = ({ precision, name, label, defaultValue, required, value, onChange, helperText, error, type, InputProps, placeholder, disabled, inputProps }) => {
    const { control } = useFormContext();

    return (
        <Grid item xs={12} sm={6}>
            <Controller 
                render={({ field }) => ( 
                    <TextField precision={precision} defaultValue={defaultValue} placeholder={placeholder} style={{ padding: 10 }} {...field} value={value} label={label} onChange={onChange} disabled={disabled} fullWidth required={required} InputProps={InputProps} type={type} helperText={helperText} error={error} inputProps={inputProps}  />)}
                control={control}
                defaultValue={value}
                // value={value}
                fullWidth
                name={name}
                label={label}
                required={required}
                register={name}
            />
         </Grid>
    )
}

export default FormInput;

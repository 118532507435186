import * as React from 'react';
import { useState } from 'react';
import { Paper, Typography, Link as MuiLink, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RemoveRedEye, Savings, ThumbUp } from '@mui/icons-material';

export const ArticleCard = ({ article }) => {
    const [elevation, setElevation] = useState(1);
    const navigate = useNavigate();

    if (!article) return null;


    const getCategoryString = (category) => {
        if(category === "personal_finance") {
            return "Personal finance"
        } else if (category === "credit_health") {
            return "Credit health"
        }

        return "Personal finance"
    }

    const onClick = () => {
        navigate(`/financial_wellness/${article.heading.toLowerCase().split(" ").join("_")}`, {state: {article}});
    }

    return (
        <>
        <meta name="description" content={article.content_raw.slice(0,150)} />
        <MuiLink
            onClick={onClick}
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Paper
                sx={{
                    height: 200,
                    width: 195,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '1em',
                }}
                elevation={elevation}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Savings fontSize='large'/>
                    <Box textAlign="center" marginTop={1}>
                        <Typography variant="subtitle1">
                            {article.heading}
                        </Typography>
                        <Typography variant="subtitle3" color="textSecondary">
                            {getCategoryString(article.category)}
                        </Typography>
                       
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-around" width="100%">
                    <Box display="flex" alignItems="center">
                        <ThumbUp color="disabled" sx={{ mr: 1 }} />
                        <div>
                            <Typography variant="subtitle2" sx={{ mb: -1 }}>
                                {article.likes}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {article.likes > 1
                                    ? 'Likes'
                                    : 'Like'}
                            </Typography>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RemoveRedEye color="disabled" sx={{ mr: 1 }} />
                        <div>
                            <Typography variant="subtitle2" sx={{ mb: -1 }}>
                                {article.reads}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                Reads
                            </Typography>
                        </div>
                    </Box>
                </Box>
            </Paper>
        </MuiLink>
        </>
    );
};
import React from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import { Grid, Typography } from "@mui/material";
import LocalizedStrings from "../../localization/Strings";
import { SubheadingWithBody } from "../support/Privacy";
import ClickEkasiButton from "../../assets/images/clickbutton.png";
import useCheckMobile from "../../common/useCheckMobile";
import EKasiPlanCard from "../../common/components/EKasiPlanCard";

export const Merchants = () => {
    const isMobile = useCheckMobile()

    return (
        <>
        <Helmet>
            <title> Payment solutions | eKasi Pay Credit </title>
            <meta name="description" content="Payment solutions in Africa" />
        </Helmet>

        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
            <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${LocalizedStrings.ekasiPaySolutions}`}
            </Typography>
             <Box style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
             <SubheadingWithBody 
             heading={LocalizedStrings.easyIntegration}
             body={`${LocalizedStrings.allowCustomersPurchase}\n${LocalizedStrings.integrationText}`} 
             />
                <img src={ClickEkasiButton} alt="eKasi Buttons"  width={isMobile ? 150 : null} height={isMobile ? 70 : null} />
            </Box>
            <SubheadingWithBody 
             heading={LocalizedStrings.integrationRisk}
             body={LocalizedStrings.integrationRiskText} 
             />
             <SubheadingWithBody 
             heading={LocalizedStrings.ourSolutions}
             body={""} 
             />
               <Grid container spacing={5} style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 20, marginTop: 10}}  >
                <Grid item >
                    <EKasiPlanCard title={LocalizedStrings.checkoutAPI} body={LocalizedStrings.checkoutAPIDetails} />
                </Grid>
                <Grid item>
                    <EKasiPlanCard title={LocalizedStrings.layByAPI} body={LocalizedStrings.layByAPIDetails} />
                </Grid>
            </Grid>
        </Box>
        
        </>
    )
}

export default Merchants;
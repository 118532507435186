import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Colours from '../../common/Colours';
import Strings from '../../localization/Strings';
import { Typography } from '@mui/material';
import { Facebook, LinkedIn } from '@mui/icons-material';

export default function Footer({showNavBar}) {
  if(!showNavBar) {
    return <div style={{backgroundColor: Colours.backgroundGrey }} />
  }

  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor={Colours.white}
        color={Colours.viridianGreen}
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Box borderColor={Colours.viridianGreen} borderBottom={3}>Help</Box>
              <Box>
                <Link href="/application" style={{ textDecoration: 'none' }} textDecoration="inherit" color="inherit">
                 {Strings.applyForCredit}
                </Link>
              </Box>
              <Box>
                <Link href="/merchant_services" style={{ textDecoration: 'none' }} textDecoration="inherit" color="inherit">
                 {Strings.ekasiPaySolutions}
                </Link>
              </Box>
              <Box>
                <Link href="/financial_wellness" style={{ textDecoration: 'none' }} textDecoration="inherit" color="inherit">
                 {Strings.myFinancialWellbeing}
                </Link>
              </Box>
              <Box>
                <Link href="/support" style={{ textDecoration: 'none' }} textDecoration="inherit" color="inherit">
                 {Strings.contact}
                </Link>
              </Box>
              <Box>
                <Link style={{ textDecoration: 'none' }} href="/faqs" color="inherit">
                  {Strings.FAQs}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderColor={Colours.viridianGreen} borderBottom={3}>Legal</Box>
                <Box>
                  <Link style={{ textDecoration: 'none' }} href="/terms" color="inherit">
                    {Strings.termsOfUse}
                  </Link>
                </Box>
                <Box>
                  <Link style={{ textDecoration: 'none' }} href="/privacy" color="inherit">
                    {Strings.privacyPolicy}
                  </Link>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderColor={Colours.viridianGreen} borderBottom={3}>{Strings.followUsOn}</Box>
              <Box>
              <Facebook style={{padding: 5}} onClick={event => {
                window.location.href='https://www.facebook.com/profile.php?id=100088804327823'
              }} />
              <LinkedIn style={{padding: 5}} onClick={event => {
                window.location.href='https://www.linkedin.com/company/ekasi-credit'
              }}   />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderColor={Colours.viridianGreen} borderBottom={3}>{Strings.ncrAccredited}</Box>
              <Box>
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.ekasiIsAccreditedRegistered}</Typography>
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.ekasiIsAccredited}</Typography>
              </Box>
              <Box>
                 <Typography style={{ fontWeight: '100', color: Colours.viridianGreen}}  textAlign="center">{Strings.ekasiDomicile}</Typography>
                 <Typography style={{ fontWeight: '100', color: Colours.viridianGreen}}  textAlign="center">{Strings.ekasiNeedEmail}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }}>
            {Strings.ekasiLoans} &reg; {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export default function EKasiLoader({ open, handleClose, text }) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress variant="indeterminate" />
        <Typography>{text}</Typography>
      </Backdrop>
    </div>
  );
}
import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EKasiLoader from '../../common/components/EKasiLoader';

const Redirector = ({getCustomerData}) => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        retrieveResults();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveResults = () => {
        setLoading(true);
        if(search !== "") {
            const searchItems = search.split("&");
            
            if(searchItems.length === 3) {
              const loanStr = searchItems[0].substring(1);
              const loanId = loanStr.substring(loanStr.indexOf("=") + 1); 
              const auth_token = searchItems[1].substring(searchItems[1].indexOf("=") + 1);
              const status = searchItems[2].substring(searchItems[2].indexOf("=") + 1);

              getCustomerData(auth_token, (responseData) => {
                const loan = responseData.data.loans.filter((loan) => loan._id === loanId);
                setLoading(false);

                // TODO: if status is 2000 put loan into verification 

                if(loan.length > 0) {
                    navigate('/onboard', {state: {loan: loan[0], status}});
                }
              }, (error) => {
                setLoading(false);
              });
            }
          }
    }
    

    return(
        <>
            <EKasiLoader open={isLoading} text={'Processing your application data...'}/>
        </>
    );







  }

  export default Redirector;
import React, { useLayoutEffect,useState } from 'react';
import Colours from '../../common/Colours';
import { Box } from '@mui/system';
import { Typography, Button, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight, Delete, Done } from '@mui/icons-material';
import EKasiDialog from '../../common/components/EKasiDialog';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import SignatureCanvas from 'react-signature-canvas'
import LocalizedStrings from '../../localization/Strings';
import EKasiLoader from '../../common/components/EKasiLoader';
import { useEffect } from 'react';
import EKasiAPIClient from '../../network/EkasiAPIClient';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

  
const SignLoan = ({ setSignedComplete, loan, token }) => {
    var sigCanvas = React.useRef(null);
    const viewRef = React.useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [index, setIndex] = useState(1);
    const [documentData, setDocumentData] = useState(null);
    // const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState("");
    const [positiveHandler, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        getContract(token, loan._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [width, setWidth] = useState(0);
  
    useLayoutEffect(() => {
      setWidth(viewRef.current.offsetWidth);
    }, []);

    const positiveClickHandler = (event) => {
        if(positiveHandler === 'sign' || positiveHandler === 'refresh') {
            refreshCustomerData()
        } else if(positiveHandler === 'contract') {

        } else if(positiveHandler === 'sign_empty') {

        }

        setPositiveHandler("");
    }


    const negativeClickHandler = (event) => {
        if(negativeHandler === 'sign') {
            
        } else if(negativeHandler === 'contract') {
            getContract(token, loan._id);
        } else if(negativeClickHandler === 'sign_empty'){

        }

        setNegativeHandler("");
    }


    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const refreshCustomerData = () => {
          setLoading(true);
          setSignedComplete();
    }

    const getContract = (token, loanId) => {
        setLoading(true)
        EKasiAPIClient.getContract(token, loanId, (response) => {
            const contract = base64ToArrayBuffer(response.data.loan_contract_base64.split(',')[1]);
            setDocumentData(contract);
        }, (error) => {
            setLoading(false)
            showDialog(error.code, error.data.msg, LocalizedStrings.retry, LocalizedStrings.ok, "contract", "contract");
        });
    }

    const signContract = (token, loanId, signatureBase64) => {
        setLoading(true);
        EKasiAPIClient.signContract(token, loanId, signatureBase64, (response) => {
            setLoading(false);
            showDialog(LocalizedStrings.signature, LocalizedStrings.documentSigning, "", LocalizedStrings.ok, "sign", "sign");
        }, (error) => {
            setLoading(false);
            showDialog(error.code, error.data.msg, "", LocalizedStrings.ok, "contract", "contract");
        });
    }

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setLoading(false)
        setNumPages(nextNumPages)
    }

    const nextPage = () => {
        setIndex(numPages === index ? 1 : index + 1);
    }

    const previousPage = () => {
        setIndex(index === 1 ? 1 : index - 1);
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
    }

    const onSubmitSignature = (event) => {
        if(documentData === null) {
            showDialog(LocalizedStrings.signature, LocalizedStrings.noDocumentToSign, "", LocalizedStrings.ok, "sign_empty", "sign_empty");
        } else if(!sigCanvas.current.isEmpty()) {
            const base64EncodedSignature = sigCanvas.current.toDataURL();
            signContract(token, loan._id, base64EncodedSignature)
        } else {
            showDialog(LocalizedStrings.signature, LocalizedStrings.pleaseSign, "", LocalizedStrings.ok, "sign_empty", "sign_empty");
        }
    }

    return (
        <>
            <EKasiLoader open={isLoading}/>
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeClickHandler} handlePositiveResponseButton={positiveClickHandler} />
            <Box ref={viewRef} sx={{ backgroundColor: Colours.white, padding: 10, borderColor: 'divider'}}>
            <Box sx={{ backgroundColor: Colours.white, borderColor: 'divider', flexDirection: 'row' }}>
                <IconButton onClick={() => previousPage()} color="primary">
                    <ArrowLeft />
                </IconButton>
                <IconButton onClick={() => nextPage()} color="primary">
                    <ArrowRight />
                </IconButton>
            </Box>
                <Document file={documentData} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                    <Page scale={2} object-fit="cover" size="A4" key={`page_${index}`} pageNumber={index} />
                </Document>
                <Typography>
                    {LocalizedStrings.signHere}
                </Typography>
                <Box sx={{ border: 2 }}>
                    <IconButton onClick={() => sigCanvas.current.clear()} color="primary">
                        <Delete />
                    </IconButton>
                    <SignatureCanvas 
                        penColor={Colours.viridianGreen}
                        ref={sigCanvas}
                        clearOnResize={true}
                        canvasProps={{ width: width - 20, height: 200,  className: 'sigCanvas'}} />
                    
                    <Button variant="contained" color="primary"  onClick={(event) => onSubmitSignature(event)} startIcon={<Done />}>
                        {LocalizedStrings.done}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default SignLoan;
import React from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import { Typography } from "@mui/material";
import LocalizedStrings from "../../localization/Strings";
import { SubheadingWithBody } from "../support/Privacy";


export const AboutUs = () => {

    return (
        <>
        <Helmet>
            <title>About us</title>
        </Helmet>

        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
            <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${LocalizedStrings.eKasiCredit} | `}
                <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                    {LocalizedStrings.aboutUs}            
                </Typography> 
            </Typography>
            <SubheadingWithBody heading={""} body={LocalizedStrings.aboutUsBody} />
        </Box>

        
        </>
    )
}

export default AboutUs;
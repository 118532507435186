import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Colours from './common/Colours';
import Home from './components/home/Home';
import Footer from './components/navbar/Footer';
import Onboard from './components/onboarding/Onboard';
import Support from './components/support/Support';
import FAQs from './components/support/FAQs';
import AboutUs from './components/about/About';
import Privacy from './components/support/Privacy';
import { useState } from 'react';
import Login from './components/accounts/Login';
import Dashboard from './components/accounts/Dashboard';
import EKasiAPIClient from './network/EkasiAPIClient';
import EKasiLoader from './common/components/EKasiLoader';
import NotFound from './components/onboarding/NotFound';
import Redirector from './components/onboarding/Redirector';
import Terms from './components/support/Terms';
import{ init } from '@emailjs/browser';
import CheckoutHome from './components/checkout/CheckoutHome';
import ArticlesList from './components/wellness/ArticlesList';
import ArticleDetials from './components/wellness/ArticleDetails';
import Merchants from './components/merchants/Merchants';
import Landing from './components/landing/Landing';
init(`${process.env.REACT_APP_EMAIL_JS_KEY}`);

let theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      }, 
    }, 
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
      text:{
        primary: Colours.viridianGreen
      },
      background: {
        default: "#f5f5f5"
      },
      secondary: {
          main: Colours.viridianGreen,
      },
      primary: {
          main: Colours.viridianGreen,
      }
    },
});

theme = responsiveFontSizes(theme);

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [name, setFirstName] = useState("Shaka Zulu");
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null);
  const [data, setUserData] = useState(null);
  const [redirectUrl, setRedirect] = useState("");
  const [showNavBar, setNavAppearance] = useState(true);
  const isSiteUp = process.env.REACT_APP_LIVE === "true"

  const clearLocalCache = () => {
    setFirstName("");
    setToken(null);
    setUserData(null);
    setAuthenticated(false);
  }

  const login = (username, password, onSuccess, onError) => {
    EKasiAPIClient.authenticate(username, password, 
    (resData) => {
      setAuthenticated(true);
      setToken(resData.data.token);
      setFirstName(resData.data.customer.name);
      setUserData(resData.data);
      onSuccess(resData);
    }, onError);
  }

  const getCustomerData = (_token, onSuccess, onError) => {
    EKasiAPIClient.getCustomer(_token,
    (res) => {
      setAuthenticated(true);
      setToken(res.data.token);
      setFirstName(res.data.customer.name);
      setUserData(res.data);
      onSuccess(res);
    }, (error) => onError);
  }
  
  function External({redirect}) {
    
    window.location.replace(redirect);
    return <EKasiLoader open={true}/>;
  }

  return (
    <Router>
    <div style={{backgroundColor: Colours.backgroundGrey }}>
      <ThemeProvider theme={theme}>
        <Navbar isAuthenticated={isAuthenticated} getCustomerData={getCustomerData} token={token} clearLocalCache={clearLocalCache} name={name} showNavBar={showNavBar} />
      <Routes>
        {
        isSiteUp ?
        <>
          <Route exact path="/" element={<Landing />}/>
          <Route exact path="/application" element={<Home userData={data} />}/>

          <Route exact path="/checkout/:id" element={<CheckoutHome setRedirect={setRedirect} setNavAppearance={setNavAppearance} userData={data} />}/>
          <Route exact path="/checkout/:id/onboard" element={<Onboard setRedirect={setRedirect} getCustomerData={getCustomerData} userData={data}/>}/>
          <Route exact path="/checkout/:id/login" element={<Login login={login} />}/>
          
          <Route path='/redirect-truid' element={<External redirect={redirectUrl}/>}/>
          <Route path='/redirect' element={<Redirector getCustomerData={getCustomerData}/>}/>
          <Route exact path="/onboard" element={<Onboard setRedirect={setRedirect} setNavAppearance={setNavAppearance} getCustomerData={getCustomerData} userData={data}/>}/>
          <Route exact path="/support" element={<Support />}/>
          <Route exact path="/faqs" element={<FAQs />} />
          <Route exact path="/merchant_services" element={<Merchants />} />
          <Route exact path="/about" element={<AboutUs />}/>
          <Route exact path="/privacy" element={<Privacy />}/>
          <Route exact path="/terms" element={<Terms />}/>
          <Route exact path="/login" element={<Login login={login} />}/>
          <Route exact path="/financial_wellness" element={<ArticlesList />}/>
          <Route exact path="/financial_wellness/:id" element={<ArticleDetials />}/>
          <Route exact path="/dashboard" element={<Dashboard getCustomerData={getCustomerData} userData={data}/>} />
        </>
        : null
        }
        <Route path="*" element={<NotFound/>} />
      </Routes>
        <Footer showNavBar={showNavBar}/>
      </ThemeProvider>
    </div>
    </Router>   
  );
}

export default App;

import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import LiquidLoader from "../../assets/animations/loader.json";
import { Backdrop } from "@mui/material";
import Colours from "../Colours";

const EKasiAnimationLoader = () => {
  const anime = useRef(null);
  const hasLoaded = useRef(null);
  useEffect(() => {
    if(!hasLoaded?.current) {
        lottie.loadAnimation({
            container: anime.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: LiquidLoader,
        });
        hasLoaded.current = true;
    } 
    // More logic goes here
  }, []);

  return (
    <div>
        <Backdrop
            ref={anime}
            sx={{backgroundColor: Colours.variantViridianGreen, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}           
        />
     </div> 
  ) 
  
};

export default EKasiAnimationLoader;
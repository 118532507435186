import Globals from "./Globals";

/* eslint-disable no-multi-str */
const Faqs = [
    {
        id: 1,
        title: "Is www.ekasicredit.co.za secure?",
        detail: "<p>Yes, the website is SSL encrypted.</p>"
    },
    {
        id: 2,
        title: "Why do I need to have a computer with a camera in order to apply?",
        detail: "\
        <p>In efforts to make the entire process self-managing and self-service. We have automated our identity verification process.\
        Our systems runs a facial recognizer between your Identity Document/Card and your image.\
        \
        </p>"
    },
    {
        id: 3,
        title: "How long does the application take?",
        detail: "<p>\
        If you have finished the entire application process and uploaded all your documents, you should get your decision in no time. Should you choose to send documentation via email we cannot guarantee good turnaround time.\
        </p>"
    },
    {
        id: 4,
        title: "What payment methods can I use to repay the credit?",
        detail: "<p>\
        All of <b>eKasi Pay's Credit Agreements</b> require customers to accept debit orders through their banks debit check system. Should a default occur however other payment methods will be made available to the you.\
        </p>"
    },
    {
        id: 5,
        title: "Who is the NCR?",
        detail: `<p>\
        The NCR is the National Credit Regulator, the NCR supports the social and economic advancement of South Africa by regulating for a fair and non-discriminatory market place for access to consumer credit; and promoting responsible credit granting and credit use, and effective redress.\
        </p>\
        \
        <p>\
        The NCR requires that all Credit Providers are registered prior to giving credit to South Africans.\
        \
        <b>eKasi Credit</b> is a registered Credit Provider with the NCR. <b>eKasi Credit</b> registration number is ${Globals.ncrNumber}.
        </p>`
    },
    {
        id: 6,
        title: "Where can I lodge a complaint?",
        detail: "<p>\
        We have our own internal <b>customer relationship management services</b> in the <b>support</b> page of this website, please make use of that.\
        \
        </p>\
        <p>\
        If you are not satisfied with the outcomes then please contact either of the following role players:<br/>\
        NCR Credit Ombud\
        <a href=http://www.ncr.org.za>http://www.ncr.org.za</a><br/>\
        <href>complaints@ncr.org.za</href><br/>\
        P O Box 209, Halfway House, 1685<br/>\
        127-15th Road, Randjespark, Midrand, JOHANNESBURG, 1685<br/>\
        </p>"
    },
    {
        id: 7,
        title: "What to do after a default in payment?",
        detail: "<p>We know that life happens. Should you default or miss the payment date, please contact our support team and we will gladly arrange other payment methods. </p>"
    },
    {
        id: 8,
        title: "What/Who is truID?",
        detail: "<p>\
        TruID is a third party service that helps <b>eKasi Credit</b> retrieve customers Bank Statements securely.<br/>\
        Please find out more about TruID on <a href=https://www.truid.co.za>https://www.truid.co.za</a>\
        </p>"
    },
    ];
// TODO: Create more faqs
export default Faqs;

import { Box } from '@mui/material';
import React from 'react';
import Colours from '../../common/Colours';
import LocalizedStrings from '../../localization/Strings';


const NotFound = () => {
    return(
        <>
        <Box sx={{ textAlign: 'center', p: 10,typography: 'h2', color: Colours.white, fontWeight: 'bold', backgroundColor: Colours.viridianGreen }}>
            404
            <Box sx={{ textAlign: 'center', typography: 'body2', color: Colours.white }}>{LocalizedStrings.pageNotFound}</Box>
        </Box>
        </>
    )


}

export default NotFound;
import React from 'react';
import { useState } from 'react';
import useCheckMobile from '../../common/useCheckMobile';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import Strings from '../../localization/Strings';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Colours from '../../common/Colours';
import Link from '@mui/material/Link';
import { useEffect } from 'react';
import { useRef } from 'react';
import EKasiLoader from '../../common/components/EKasiLoader';
import EKasiDialog from '../../common/components/EKasiDialog';
import LocalizedStrings from '../../localization/Strings';
import { useNavigate } from 'react-router-dom';

export const OTP = ({onPrev,  onSubmit, defaultData}) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [token, setToken] = useState('');
    const [code, setCode] = useState('');
    const [isLoading, setLoading] = useState(false);
    const isMobile = useCheckMobile();
    const [number, setNumber] = useState("000 00 00 000");
    const [timer, setTimer] = useState(-100);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") 

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
    }

    useEffect(() => {
        if(typeof defaultData?.account !== "undefined" || typeof defaultData?.customer !== "undefined") {
            setNumber(defaultData?.account?.phone || defaultData?.customer?.phone_number);
            requestVerfication(defaultData?.account?.phone || defaultData?.customer?.phone_number)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [start, setStart] = useState(false);
    const firstStart = useRef(true);
    const tick = useRef(); // <-- React ref
  
    useEffect(() => {
      if (firstStart.current) {
        firstStart.current = !firstStart.current;
        return;
      }
  
      if (start) {
        tick.current = setInterval(() => { // <-- set tick ref current value
          setTimer((timer) => {
            return timer - 1});
        }, 1000);
      } else {
        clearInterval(tick.current); // <-- access tick ref current value
      }
  
      return () => clearInterval(tick.current); // <-- clear on unmount!
    }, [start]);


    const requestVerfication = (phoneNumber) => {
        setLoading(true);
        EKasiAPIClient.requestVerification(phoneNumber, (res) => {
            setLoading(false);
            const t = res.data.resend_timer_seconds
            setTimer(t);
            setToken(res.data.verification_token);
            setCode(res.data.verification_code);
            setStart(true);
        }, (error) => {
            setLoading(false);
            showDialog(error.code, `${error.data.msg}`, "", Strings.ok, null, null);
        });
    }

    const completeVerification = (numberP, codeP, tokenP) => {
        setLoading(true);
        EKasiAPIClient.completeVerification(numberP, codeP, tokenP, (data) => {
            setLoading(false);
            onSubmit();
        }, (error) => {
            setLoading(false);
            setOtp("");
            showDialog(error.code, `${error.data.msg}`, "", Strings.ok, null, null);
        });
    }

    const handleChange = (newValue) => {
      setOtp(newValue)
      if(newValue.length === 4 && Number(newValue) === code) {
        setTimer(-100);
        setStart(false);
        completeVerification(number, Number(newValue), token);
      } else if (newValue.length === 4) {
        showDialog(Strings.verification, Strings.verificationIncorrect, "", Strings.ok, null, null);
      }
    }

    const formatTime = (mins, secs) => {
        return ('0'  + mins).slice(-2)+':'+('0' + secs).slice(-2)
    }

    return (
        <>
            <EKasiLoader open={isLoading}/>
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
            <Box style={{ display: 'flex', maxWidth: !isMobile ? '30%': '', flexDirection: 'column', alignSelf: 'center', margin: 30}}>
                <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{Strings.weSentVerificationCode.replace("%1", number)}</Typography>
                <MuiOtpInput value={otp} onChange={handleChange} />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' , margin: 30}}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        if(isUserDashboard) {
                            navigate("/dashboard");
                            return
                        }

                        onPrev()
                    }}
                >
                    {isUserDashboard ? LocalizedStrings.cancel : LocalizedStrings.back}
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        setOtp("")
                    }}
                >
                {Strings.clear}
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    style={{ textDecoration: timer <= 0 ? '' : 'none' }}
                    onClick={() => {
                        if(timer <= 0) {
                            requestVerfication(number)
                        }
                    }}
                >
                {timer <= 0 ? Strings.resend : `${Strings.resendIn}${formatTime(parseInt(timer / 60), timer % 60)}`}
                </Link>
                </Box>
            </Box>
        </>
    )




}
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import Colours from '../../common/Colours';
import EKasiDialog from '../../common/components/EKasiDialog';
import EKasiLoader from '../../common/components/EKasiLoader';
import FormInput from '../../common/FormInput';
import useCheckMobile from '../../common/useCheckMobile';
import LocalizedStrings from '../../localization/Strings';
import EKasiAPIClient from '../../network/EkasiAPIClient';

export const IdentityVerification = ({ onPrev, onSubmit, defaultData }) => {
    const navigate = useNavigate();
    const isMobile = useCheckMobile();
    const methods = useForm();
    const webcamRef = React.useRef(null);
    const [id, setID] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [applicationData, updateApplicationData] = useState(defaultData);

    const capture = React.useCallback(
      (_id) => {
        if(isUserDashboard) {
          const imageSrc = webcamRef.current.getScreenshot();
          onSubmit({...applicationData, loan_applicants_image_base64: imageSrc});
        } else if (_id !== null) {
          const imageSrc = webcamRef.current.getScreenshot();
          onSubmit({...applicationData, loan_applicants_image_base64: imageSrc, id_document: id});
        } else {
          showDialog(LocalizedStrings.identityVerification, LocalizedStrings.idUploadFailure, "", LocalizedStrings.ok, null, null);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [webcamRef]
    );
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState("");
    const [positiveHandler, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") 

    useEffect(() => {
      if(isUserDashboard) {
        preassess(defaultData);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const negativeButtonHandler = (event) => {
      if(negativeHandler === "DECLINE_OFFER_CALLBACK") {
        navigate("/dashboard");
      }

      setNegativeHandler("");
    }

    const positiveButtonHandler = (event) => {
      if(positiveHandler === "OK_CALLBACK") {

      } else if(positiveHandler === "ACCEPT_OFFER_CALLBACK") {
        onSubmit(applicationData);
      }

      setPositiveHandler("");
    }

    const preassess = (data) => {
      setLoading(true);
      EKasiAPIClient.preassess(data, (response) => {
        setLoading(false);
        if(response.code === "APPROVED") {
          const amount = response?.data?.loan.amount;
          const totalRepayment = response?.data?.loan.total_repayment;
          const days = response?.data?.loan.term_in_days;
          const interestRate = response?.data?.loan.interest_rate;
          const initiationFee = response.data?.loan.initiation_fee;
          const serviceFee = response.data?.loan.service_fee;
          const fees = response.data?.loan.fees;

          updateApplicationData({loan: { ...applicationData.loan, amount, totalRepayment, days, interestRate, initiationFee, serviceFee, fees }})
        } else if(response.code === "DECLINED" && response?.data?.loan !== undefined) {
          const amount = response?.data?.loan.amount;
          const totalRepayment = response?.data?.loan.total_repayment;
          const days = response?.data?.loan.term_in_days;
          const interestRate = response?.data?.loan.interest_rate;
          const initiationFee = response.data?.loan.initiation_fee;
          const serviceFee = response.data?.loan.service_fee;
          const fees = response.data?.loan.fees;

          updateApplicationData({loan: { ...applicationData.loan, amount, totalRepayment, days, interestRate, initiationFee, serviceFee, fees }})
          showDialog(LocalizedStrings.affordabilityAssesment, LocalizedStrings.weWouldLikeToOfferYou.replace("%1", `R ${amount.toFixed(2)}`).replace("%2", `R ${totalRepayment.toFixed(2)}`), LocalizedStrings.decline, LocalizedStrings.accept, "DECLINE_OFFER_CALLBACK", "ACCEPT_OFFER_CALLBACK");
          // TODO: On accept / decline perform action
        } else {
          showDialog(LocalizedStrings.affordabilityAssesment, LocalizedStrings.weCantGiveYouAmount, "", LocalizedStrings.ok,  "", "OK_CALLBACK");
        }
        }, (error) => {
          setLoading(false);
          showDialog(error.code, error.data.msg, "", "Ok", null, null);
        })
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    const videoConstraints = {
        facingMode: "user"
      };

    const WebcamCapture = () => (
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          videoConstraints={videoConstraints}
          onUserMediaError={(error) => { showDialog(LocalizedStrings.captureError, `${LocalizedStrings.somethingWentWrongMostLikely}${error}`, "", LocalizedStrings.ok, null, null); }}
          style={{ display: 'flex',borderWidth: 10, borderColor: Colours.green, borderRadius: 30, textAlign: 'center', margin: 20, }}
        >
        </Webcam>
      );

   return(
        <>
            <EKasiLoader open={isLoading}/>
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeButtonHandler} handlePositiveResponseButton={positiveButtonHandler} />
            <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{LocalizedStrings.identityVerification}</Typography>
            <Box style={{display: 'flex', maxWidth: '40%', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                <WebcamCapture />
                {
                  !isUserDashboard ? 
                  <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit((data) => {
                    
                  })}>
                    <Grid container spacing={3}>
                      <FormInput type={"file"} inputProps={{ accept: 'image/*, application/pdf' }} helperText={LocalizedStrings.uploadID} onChange={(event) => {  
                        if (event.target.files !== null && event.target?.files?.length > 0) {
                          setID(event.target.files[0]);
                        } else {
                        }
                      }} 
                      name="identity-document" />
                    </Grid>
                  </form>
                  </FormProvider> 
                : null
                }
                <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',}}>
                    {
                        !isUserDashboard ?
                    <Button
                        onClick={onPrev}
                        style={{ backgroundColor: Colours.variantViridianGreen, marginTop: 20 }} 
                        fullWidth 
                        disableElevation
                        variant="contained"
                    >
                        {LocalizedStrings.back}
                    </Button>
                    : null
                    }
                    <Button
                        onClick={() => capture(id)}
                        style={{ backgroundColor: Colours.variantViridianGreen, marginTop: 20 }} 
                        fullWidth 
                        disableElevation
                        variant="contained"
                    >
                        {LocalizedStrings.capturePhoto}
                    </Button>
                </Box>
            </Box>
        </>
   );
}
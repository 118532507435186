import React, {useState} from 'react';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import Helmet from 'react-helmet';
import { Box } from '@mui/system';
import { Button, Container, ListItem, Typography, Slider, styled } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useCheckMobile from '../../common/useCheckMobile';
import Globals from '../../common/Globals';
import EKasiDialog from '../../common/components/EKasiDialog';
import moment from 'moment';
import EKasiToolTip from '../../common/components/EKasiToolTip';
import EKasiAnimationLoader from '../../common/components/EKasiAnimationLoader';
import { useEffect } from 'react';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import { CloudinaryContext, Image } from 'cloudinary-react';

const PrettoSlider = styled(Slider)({
    color: Colours.variantViridianGreen,
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: `2px solid ${Colours.maroon}`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

const Home = ({ userData }) => {
    const navigate = useNavigate();
    const isMobile = useCheckMobile();
    const interestRate = Globals.iRate;
    const daysInMonth = Globals.daysInAMonth;
    const [actaulRepaymentDate, setRepaymentDate] = useState(moment().format('Do MMMM, YYYY'));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getLoanFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useState({
        minLoan: Globals.minLoan,
        maxLoan: Globals.maxLoan,
        minLoanTerm: 0,
        maxLoanTerm: 30,
        maxInitiationPercentage: Globals.maxInitionPercentage,
        maxServiceFee: Globals.maxServiceFee,
        baseInitiationFee: Globals.baseInitiationFee,
        residualPercentageFee: Globals.initiationFeeProfitMarginPercentage,

        amount: Globals.minLoan,
        days: Globals.minLoanTerm,
        interestRate: interestRate,
        interestPerDay: 0.00,
        initiationFee: 0.00,
        totalRepayment: 0.00,
        serviceFee: 0.00,
        fees: 0.00,
    });

    const getLoanFees = () => {
        setLoading(true);
        const token = userData?.token 
        EKasiAPIClient.getLoanFees(token, (res) => {
            const data = res.data;
            const {
            max_loan_term_in_days,
            min_loan_term_in_days,
            min_loan_amount_zar,
            max_loan_amount_zar,
            max_initiation_fee_percentage,
            max_service_fee_zar,
            base_initiation_fee_zar,
            residual_initiation_fee_percentage,
            interest_rate,
            } = data;

            const r = interest_rate / 100.0;
            const term =  Number(min_loan_term_in_days);
            const newAmount = min_loan_amount_zar;
            const i = (r / daysInMonth) * term;
            const accruedAmount = newAmount * (1 + i);
            setRepaymentDate(moment().add(term, 'days').format('Do MMMM, YYYY'));
            const initFee = Number(base_initiation_fee_zar) + (state.amount * Number(residual_initiation_fee_percentage) / 100.00);
            const interestAmountPerDay = newAmount * (r / daysInMonth);
    
            const maxInitFee = (newAmount *  Number(max_initiation_fee_percentage) / 100.00);
            const boundedInitFee = initFee > maxInitFee ? maxInitFee : initFee;

            setState({
                minLoan: min_loan_amount_zar,
                maxLoan: max_loan_amount_zar,
                minLoanTerm: min_loan_term_in_days,
                maxLoanTerm: max_loan_term_in_days,
                maxInitiationPercentage: max_initiation_fee_percentage,
                maxServiceFee: max_service_fee_zar,
                baseInitiationFee: base_initiation_fee_zar,
                residualPercentageFee: residual_initiation_fee_percentage,
        
                amount: min_loan_amount_zar,
                days: min_loan_term_in_days,
                interestRate: interest_rate,

                initiationFee: boundedInitFee,
                interestPerDay: interestAmountPerDay,
                fees: boundedInitFee + max_service_fee_zar,
                serviceFee: boundedInitFee + max_service_fee_zar + (i * newAmount), // This is all the fees together
                totalRepayment: accruedAmount + boundedInitFee + max_service_fee_zar,
            });
            setLoading(false);
        }, (error) => {
            const r =  Globals.iRate / 100.0;
            const term =  Number(Globals.minLoanTerm);
            const newAmount = Globals.minLoan;
            const i = (r / daysInMonth) * term;
            const accruedAmount = newAmount * (1 + i);
            setRepaymentDate(moment().add(term, 'days').format('Do MMMM, YYYY'));
            const initFee = state.baseInitiationFee + (state.amount * state.residualPercentageFee / 100.00);
            const interestAmountPerDay = newAmount * (r / daysInMonth);
    
            const maxInitFee = (newAmount * state.maxInitiationPercentage / 100.00);
            const boundedInitFee = initFee > maxInitFee ? maxInitFee : initFee;


            setState({
                minLoan: Globals.minLoan,
                maxLoan: Globals.maxLoan,
                minLoanTerm: Globals.minLoanTerm,
                maxLoanTerm: Globals.maxLoanTerm,
                maxInitiationPercentage: Globals.maxInitionPercentage,
                maxServiceFee: Globals.maxServiceFee,
        
                amount: Globals.minLoan,
                days: Globals.minLoanTerm,
                interestRate: Globals.iRate,

                initiationFee: boundedInitFee,
                interestPerDay: interestAmountPerDay,
                fees: boundedInitFee + state.maxServiceFee,
                serviceFee: boundedInitFee + state.maxServiceFee + (i * newAmount), // This is all the fees together
                totalRepayment: accruedAmount + boundedInitFee + state.maxServiceFee,
            });
            setLoading(false);
            showDialog(Strings.loanCalculator,Strings.fetchFailed, Strings.retry, Strings.contactSupport, "fetch_failed", "fetch_failed");
        });
    }

    const onAmountChange = (event, value) => {
        const newAmount = value;
        if(state.days !== 0) {
            // TODO: Recomputed this maybe check performance
            const r = interestRate / 100.00;
            const i = (r / daysInMonth) * state.days;
            const accruedAmount = newAmount * (1 + i);
            const initFee = state.baseInitiationFee + (newAmount * state.residualPercentageFee / 100.00);
            const interestAmountPerDay = newAmount * (r / daysInMonth);
            const maxInitFee = (newAmount * state.maxInitiationPercentage / 100.00);
            const boundedInitFee = initFee > maxInitFee ? maxInitFee : initFee;

            setState({
                ...state,
                amount: newAmount,
                initiationFee: boundedInitFee,
                interestPerDay: interestAmountPerDay,
                fees: boundedInitFee + state.maxServiceFee,
                serviceFee: boundedInitFee + state.maxServiceFee + (i * newAmount), // This is all the fees together
                totalRepayment: accruedAmount + boundedInitFee + state.maxServiceFee,
            });
        } else {
            setState({
                ...state,
                amount: newAmount,
            });

        }
    }

    const isThisMonthLoan = (timestamp) => {
        return moment.unix(timestamp).isSame(moment(), 'year') && moment.unix(timestamp).isSame(moment(), 'month');
    }


    // TODO: LOAN APP BLOCKING
    const isLoggedInAndHasLoansInPayment = () => {
        if (typeof userData !== "undefined" && userData !== null) {
            const loanInPayment = userData?.loans.find((loan) => {
                return isThisMonthLoan(loan.created_at) && (loan.status === "PAID_OUT" ||  loan.status === "PENDING_PAYMENT" || loan.status === "DECLINED");
            });

            return ((typeof loanInPayment) !== "undefined");
        }

        return true;
    }

    const onNavigateToOnboarding = () => {
        if(!isLoggedInAndHasLoansInPayment()) {
            showDialog(Strings.alreadyHaveALoan,Strings.youCannotApplyForTwoLoans, Strings.cancel, Strings.contactSupport, "", "");
            return
        } 
        navigate('/onboard', { state: { loan: {...state} } });
    }

    const onTermChange = (event, value) => {
        if( value === 0) {
             setRepaymentDate(moment().format('Do MMMM, YYYY'));
             setState({
                minLoan: state.minLoan,
                maxLoan: state.maxLoan,
                minLoanTerm: state.minLoanTerm,
                maxLoanTerm: state.maxLoanTerm,
                maxInitiationPercentage: state.maxInitiationPercentage,
                maxServiceFee: state.maxServiceFee,
                residualPercentageFee: state.residualPercentageFee,
        
                amount: state.minLoan,
                days: state.minLoanTerm,
                interestRate: state.interestRate,
                interestPerDay: 0.00,
                initiationFee: 0.00,
                totalRepayment: 0.00,
                serviceFee: 0.00,
                fees: 0.00,
            });
            return
        }

        const r = interestRate/100.0;
        const term =  Number(value);
        const i = (r / daysInMonth) * term;
        const accruedAmount = state.amount * (1 + i);
        setRepaymentDate(moment().add(term, 'days').format('Do MMMM, YYYY'));
        const initFee = state.baseInitiationFee + (state.amount * (state.residualPercentageFee / 100.00));
        const interestAmountPerDay = state.amount * (r / daysInMonth);

        const maxInitFee = (state.amount * state.maxInitiationPercentage / 100.00);
        const boundedInitFee = initFee > maxInitFee ? maxInitFee : initFee;

        setState({
            ...state,
            days: term,
            initiationFee: boundedInitFee,
            interestPerDay: interestAmountPerDay,
            serviceFee: boundedInitFee + state.maxServiceFee + (i * state.amount),
            fees: boundedInitFee + state.maxServiceFee, // Excludes interest
            totalRepayment: accruedAmount + boundedInitFee + state.maxServiceFee,
        });
    }

    const LoanCalculator = ({state}) => (
        <Container style={{ display: 'flex', flexDirection: 'row', backgroundColor: Colours.white, borderRadius: 15, padding: 0, marginBottom: 20}}>
            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: Colours.variantViridianGreen, borderRadius: 15}}>
                {/* <CalculateOutlined fontSize="large" style={{color: Colours.white, paddingTop: 15}} /> */}
                {/* <img height={"50%"} width={"50%"} alt="Cash loan" src={CashMoney} /> */}
                <Image
                    responsive
                    dpr="auto"
                    width="auto"
                    crop="scale"
                    responsiveUseBreakpoints="true"
                    angle="0"
                    publicId="ekasicreditassets/cashmoney_swhzkf.png"
                >
                </Image>

                <Typography style={{ padding: 15, color: Colours.white}}  textAlign="center">{Strings.getCreditToday}</Typography>
                <ListItem sx={{ display: 'list-item', color: Colours.white }}>
                    {Strings.easyToApply}
                </ListItem>
                <ListItem sx={{ display: 'list-item', color: Colours.white }}>
                    {Strings.getCashToBank}
                </ListItem>
                <ListItem sx={{ display: 'list-item', color: Colours.white }}>
                    {Strings.repayLoan}
                </ListItem>
            </Container>
            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: Colours.white, borderRadius: 15, padding: 15}}>
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.howMuchCash}</Typography>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.adjustCashSlider}</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 20, color: Colours.variantViridianGreen}}  textAlign="center">R {state.amount.toFixed(2)}</Typography>
                <PrettoSlider
                    name="amount"
                    valueLabelDisplay="auto"
                    defaultValue={state.amount}
                    onChangeCommitted={onAmountChange}
                    step={1}
                    min={state.minLoan}
                    max={state.maxLoan} />
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}  textAlign="center">{Strings.repayIn}</Typography>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.adjustDaysSlider}</Typography>
                <Typography style={{ display: "inline", fontWeight: '100', fontSize: 15, color: Colours.variantViridianGreen}}  textAlign="center">
                    {`${state.days.toString()} day(s) `}
                    <Typography style={{ display: "inline", fontWeight: 'bold', fontSize: 20, color: Colours.variantViridianGreen}}  textAlign="center">
                        {actaulRepaymentDate}
                    </Typography>
                </Typography>

                <PrettoSlider
                    name="days"
                    defaultValue={state.days}
                    valueLabelDisplay="auto"
                    onChangeCommitted={onTermChange}  
                    step={1} 
                    min={state.minLoanTerm} 
                    max={state.maxLoanTerm} />
                <Box style={{display: 'flex', flexDirection: 'row'}}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 12, color: Colours.viridianGreen}}  textAlign="center">{Strings.totalFees}</Typography>
                    <EKasiToolTip arrow={true}
                        title={
                            <React.Fragment>
                            <Typography  style={{ fontWeight: '100', fontSize: 10}} color="inherit">
                                <b>
                                    <u>
                                        {Strings.feesBreakdown}
                                    </u>
                                </b>
                                <li>
                                    {`${Strings.initiationFeeR}${Number(state.initiationFee).toFixed(2)}`}
                                </li>
                                <li>
                                    {`${Strings.serviceFeeR}${Number(state.maxServiceFee).toFixed(2)}`}
                                </li>
                                <li>
                                    {`${Strings.interestAmountR}${Number(state.serviceFee === 0 ? 0.00 : state.serviceFee - state.maxServiceFee - state.initiationFee).toFixed(2)}`}
                                </li>
                                <li>
                                {`${Strings.interestRatePerc} ${Number(state.interestRate).toFixed(2)}% ${Strings.perMonth}`}
                                </li>
                                <li>
                                {`${Strings.vatFees}`}
                                </li>
                            </Typography>
                    </React.Fragment>
                  }>
                    <Info color="primary" fontSize='small'/>
                    </EKasiToolTip>
                </Box>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">R {state.serviceFee.toFixed(2)}</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">{Strings.totalRepayment}</Typography>
                <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen}}  textAlign="center">R {state.totalRepayment.toFixed(2)}</Typography>
                <Button 
                    disabled={state.days === 0} 
                    onClick={onNavigateToOnboarding}
                    style={{ maxWidth: "40%", backgroundColor: Colours.variantViridianGreen, margin: 15, borderRadius: isMobile ? 13 : 28 }}
                    fullWidth 
                    disableElevation 
                    variant="contained"
                >
                    {Strings.applyNow}
                </Button>

            </Container>
        </Container>
    );

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [negativeHandler, setNegativeHandler] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [positiveHandler, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    
    const negativeAction = (e) => {
        if(negativeHandler === "fetch_failed") {
            getLoanFees()
        }

        setNegativeHandler("")
    }

    const positiveAction = (e) => {
        if(positiveHandler === "fetch_failed") {
            navigate('/support');
            setPositiveHandler("")
        } else {
            setPositiveHandler("")
            navigate('/support');
        }
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
      }
      const description = `${Strings.easyToApply}${Strings.getCashToBank}${Strings.repayLoan}`;
    return (
        <>
            <Helmet>
                <title>{Strings.loansCalc}</title>
                <meta name="description" content={description.slice(0,150)} />
            </Helmet>
            <CloudinaryContext cloudName="dfu4oiijg">
                {isLoading ? <EKasiAnimationLoader /> : null}
                <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeAction} handlePositiveResponseButton={positiveAction} />
                <Box height={'100%'} style={{ display: 'flex', height: '100%', flex: 1, flexDirection: 'column', backgroundColor: Colours.backgroundGrey, margin: 20, padding: 10}}>
                    <LoanCalculator state={state}/>
                </Box>
            </CloudinaryContext>
        </>
    )
}


export default Home;
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import { Box } from '@mui/system';
import { Typography, Button, FormLabel, RadioGroup, Radio, FormHelperText, FormControlLabel, FormControl, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, PrivacyTip, Security, StickyNote2 } from '@mui/icons-material';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import { useState } from 'react';
import EKasiLoader from '../../common/components/EKasiLoader';
import EKasiDialog from '../../common/components/EKasiDialog';
import LocalizedStrings from '../../localization/Strings';

const Approved = ({ onSubmit, defaultData }) => {
        let navigate = useNavigate();
        const [isLoading, setLoading] = useState(false);
        const [loanMessage, setLoanMessage] = useState("");
        const [loanStatus, setStatus] = useState("");
        const [buttonLabel, setButtonLabel] = useState(Strings.done);
        const [loaderText, setLoaderText] = useState(Strings.verifyingInfo);
        const [securityCheck, setSecurityCheck] = useState(false);
        const [assessmentId, setId] = useState("");
        const [questions, setQuestions] = useState([]);
        const [answers, setAnswers] = useState([]);

        const [title, setTitle] = useState("");
        const [message, setMessage] = useState("");
        const [negativeTitle, setNegativeTitle] = useState("");
        const [positiveTitle, setPositiveTitle] = useState("");
    
        const [negativeHandler, setNegativeHandler] = useState("");
        const [positiveHandler, setPositiveHandler] = useState("");
        const [openDialog, setOpenDialog] = useState(false);

        useEffect(() => {
            setAnswers([])
            verifyAffordability(defaultData.token, defaultData.loan._id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const negativeAction = (e) => {
          setNegativeHandler("");
          setPositiveHandler("");
        }

        const positiveAction = (e) => {
          if(positiveHandler === "customer") {
            setNegativeHandler("");
            setPositiveHandler("");

            return
          } else if(positiveHandler === "security_retry") {
            submitQuestionsAnswers(defaultData.token, assessmentId, answers);
            setNegativeHandler("");
            setPositiveHandler("");
            return
          }

          verifyAffordability(defaultData.token, defaultData.loanId);
        }

        const onActionButtonClick = (e) => {
           if(e.target.innerText === Strings.contactSupport) {
            navigate('/support');
           } else {
            onSubmit({});
           }
        }

        const submitQuestionsAnswers = (token, assessmentIdP, answersP) => {
          setLoaderText(LocalizedStrings.submittingQuestions)
          setLoading(true)
          EKasiAPIClient.submitSecurityAnswers(token, assessmentIdP, answersP, (response) => {
            if(response.code === "SECURITY_QUESTIONS_PASSED") {
              setLoading(false);
              onSubmit({});
            } else {
              setLoaderText(Strings.verifyingInfo);
              setSecurityCheck(false);
              setLoading(false);
              setStatus("SECURITY_QUESTIONS_ANSWERS_INCORRECT");
              setLoanMessage(response.data.msg);
              setButtonLabel(Strings.contactSupport);
            }
          }, (error) => {
            setLoaderText(Strings.verifyingInfo);
            setLoading(false);
            showDialog(error.code, error.data.msg, Strings.cancel, Strings.retry, null, "security_retry");
          }) 
        }

        const verifyAffordability = (token, loanId) => {
          setLoading(true)
          EKasiAPIClient.verifyAffordability(token, loanId, (response) => {
            setStatus(response.code);
            setLoanMessage(response.data.msg)
            if(response.code === "ok") {
              setLoading(false);
              setButtonLabel(Strings.proceedToSign);
            }else if(response.code === "ADDITIONAL_SECURITY_VERIFICATION_REQUIRED") {
              setSecurityCheck(true)
              setId(response.data.assessment_id);
              setQuestions(response.data.questions);
              setStatus(response.code);
              setLoading(false);
            } else  {
              setLoading(false);
              setStatus("");
              setButtonLabel(Strings.contactSupport);
            }
          }, (error) => { 
            setLoading(false);
            setStatus("");
            setLoanMessage(Strings.errorAutoAssess);
            setButtonLabel(Strings.contactSupport);
            if(error.code !== "SECURITY_QUESTIONS_ANSWERS_INCORRECT") {
              showDialog(error.code, error.data.msg, Strings.cancel, Strings.retry,null, null);
            }
          });
        }

        const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
          setTitle(title);
          setMessage(message);
          setNegativeTitle(negativeTitle);
          setNegativeHandler(handleNegativeResponseButton);
          setPositiveTitle(positiveTitle);
          setPositiveHandler(handlePositiveResponseButton);
          setOpenDialog(true);
        }

        const IconState = ({status}) => {
          if(status === "ok") {
            return(<CheckCircle fontSize='large' style={{  fontWeight: 'bold',color: Colours.viridianGreen, alignSelf: 'center'}}/>);
          } else if(status === "ADDITIONAL_SECURITY_VERIFICATION_REQUIRED") {
            return(<Security fontSize='large' style={{  fontWeight: 'bold',color: Colours.viridianGreen, alignSelf: 'center'}}/>);
          } else if(status === "SECURITY_QUESTIONS_ANSWERS_INCORRECT") {
            return(<PrivacyTip fontSize='large' style={{  fontWeight: 'bold',color: Colours.viridianGreen, alignSelf: 'center'}}/>);
          }  else {
            return(<StickyNote2 fontSize='large' style={{  fontWeight: 'bold',color: Colours.viridianGreen, alignSelf: 'center'}}/>);
          }
        }

        const handleRadioChange = (event, id, options) => {
            let answerIdx = options.findIndex((option) => { return option === `${event.target.value}`});
            let answerExistsIdx = answers.findIndex((ans) => { return id === ans.id});
            let questionAns = [{
              id,
              answer: answerIdx,
            }]
            let allCurrentAns = answers

            if(answerExistsIdx) {
              delete allCurrentAns[answerExistsIdx]
            }
            allCurrentAns.push(questionAns);
        };

        const Question = ({questionP, options, id, idx}) => (
          <Box style={{marginTop: 8}}>
            <FormLabel id={id}>{idx + 1}.{questionP}</FormLabel>
            <RadioGroup
              required
              aria-labelledby="demo-error-radios"
              name={questionP}
              onChange={(e) => handleRadioChange(e, id, options)}
            >
              { 
              options.map((option,idx) => {
                  return (<FormControlLabel key={`${id}${idx}`} value={option} control={<Radio />} label={option} />)
              })
              }
            </RadioGroup>
          </Box>
        )

        const handleSubmit = (event) => {
          event.preventDefault();
          submitQuestionsAnswers(defaultData.token, assessmentId, answers);
        };

        const SecurityQuestionsRadios = ({questionsP}) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormControl sx={{ m: 0 }} variant="standard">
                { 
                  questionsP.map((q, idx) => {
                      return <Question idx={idx} key={q.id} id={q.id} questionP={q.question} options={q.options}/>
                  })
                }
                <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                  {LocalizedStrings.submitAnswers}
                </Button>
              </FormControl>
            </form>
          );
        }

        return (
            <>
                <EKasiLoader open={isLoading} text={loaderText} />
                <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeAction} handlePositiveResponseButton={positiveAction} />
                { securityCheck ? 
                  <Box style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', margin: 30}}>
                    <IconState status={loanStatus}/>
                    <Typography style={{ fontWeight: 'bold',color: Colours.variantViridianGreen, alignSelf: 'center'}} >{loanMessage}</Typography>
                    <SecurityQuestionsRadios questionsP={questions}/>
                  </Box>
                : 
                  <Box style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', margin: 30}}>
                    <IconState status={loanStatus}/>
                    <Typography style={{ fontWeight: 'bold',color: Colours.variantViridianGreen}} >{loanMessage}</Typography>
                    <Button onClick={(e) => {onActionButtonClick(e)}} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginTop: 20 }} fullWidth disableElevation variant="contained">{buttonLabel}</Button>
                  </Box>
                }
            </>
        );
 }

 export default Approved;

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import Colours from '../Colours';
import useCheckMobile from '../useCheckMobile';

const EKasiToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props}  enterTouchDelay={useCheckMobile() ? 0 : 700} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colours.variantViridianGreen,
      color: Colours.white,
      maxWidth: 440,
      fontSize: theme.typography.pxToRem(15),
      border: `1px solid ${Colours.white}`,
    },
  }));

  export default EKasiToolTip;
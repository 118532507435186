import React, { useState } from 'react';
import { Container, Typography, Divider, IconButton} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Colours from '../Colours';

const EKasiQuestionDropDownItem = ({ title, details }) => {
    const [showDetailsText, setShowDetailsText] = useState(false)

    return (
        <Container>
            <Container  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                <Typography sx={{ textAlign: 'left', typography: 'h6', color: Colours.viridianGreen, fontWeight: 'bold' }} gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>    
                </Typography>
           
            {
                showDetailsText ? 
                <Typography sx={{ typography: 'body', color: Colours.black, fontWeight: '300' }} style={{whiteSpace: 'pre-line'}} gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: details }}></div>    
                </Typography>
                : null
            }
            {
                !showDetailsText ?
                <IconButton color="primary" onClick={() => {setShowDetailsText(true)}} >
                <KeyboardArrowDown />
                </IconButton>
                : 
                <IconButton color="primary" onClick={() => {setShowDetailsText(false)}} >
                    <KeyboardArrowUp />
                </IconButton>
            }
          
            </Container>
            <Divider />
        </Container>
    )
}

export default EKasiQuestionDropDownItem
import React, {createRef, useEffect, useState} from 'react';
import 'react-phone-number-input/style.css'
import { useForm, FormProvider } from 'react-hook-form';
import { Typography, InputLabel, Divider, Grid, Button, InputAdornment, IconButton, TextField, Select, MenuItem, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import luhn from 'luhn';
import Colours from '../../common/Colours';
import Strings from '../../localization/Strings';
import FormInput from '../../common/FormInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useCheckMobile from '../../common/useCheckMobile';
import PhoneInput from 'react-phone-number-input'
import NumberFormat from 'react-number-format';
import PhoneInputField from '../../common/PhoneInputField';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import Errors from '../../common/Errors';
import EKasiDialog from '../../common/components/EKasiDialog';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import Globals from '../../common/Globals';
import EKasiLoader from '../../common/components/EKasiLoader';
import EKasiToolTip from '../../common/components/EKasiToolTip';
import { useNavigate, useParams } from 'react-router-dom';


const RegistrationForm = ({onSubmit, defaultData}) => {
    const methods = useForm();
    const navigate = useNavigate();
    const isMobile = useCheckMobile();
    const [showPassword, setShowPassword] = useState(false);
    const [isValidPhone, setValidPhone] = React.useState(false);
    const [employerName, setEmployerName] = useState("");
    const [employmentIndustry, setEmploymentIndustry] = useState("");
    const [employmentStatus, setEmploymentStatus] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [salary, setSalary] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [bank, setBank] = useState("");
    const [showProceedButton, setShowProceedButton] = useState(false);
    const [visiblePassword, setPasswordVisibility] = useState(false);

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [notUnderReviewAccepted, setNotUnderReviewAccepted] = useState(false);
    const [receivedSalaryInLastThreeMonths, setReceivedSalaryInLastThreeMonths] = useState(false);

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const ref = createRef();
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") ;
    const [isLoading, setLoading] = useState(false);
    const { id } = useParams();


    useEffect(() => {
      if(typeof defaultData?.account !== "undefined" || isUserDashboard) {
        setShowPassword(true);
        setEmployerName(defaultData?.account?.employerName || defaultData?.customer?.employer_name)
        setEmail(defaultData?.account?.email || defaultData?.customer?.email)
        setFirstName(defaultData?.account?.firstname || defaultData?.customer?.name)
        setLastName(defaultData?.account?.lastName || defaultData?.customer?.last_name)
        setPassword(defaultData?.account?.password)
        setPhone(defaultData?.account?.phone || defaultData?.customer?.phone_number)
        setUsername(defaultData?.account?.username || defaultData?.customer?.username)
        setSalary(defaultData?.account?.salary || defaultData?.customer?.salary_paid_in_account)
        setEmploymentIndustry(defaultData?.account?.employmentIndustry  || _employmentIndustries.filter((item) => item.label.toLowerCase() === defaultData?.customer?.employment_industry.toLowerCase())[0].label)
        setEmploymentStatus(defaultData?.account?.employmentStatus || _employmentStatus.filter((item) => item.label.toLowerCase() === defaultData?.customer?.employment_status.toLowerCase())[0].label)
        setBank(defaultData?.account?.bank || _banks.filter((item) => item.label.toLowerCase() === defaultData?.customer?.bank_name.toLowerCase())[0]?.label)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _banks = [{id: 'CP', label: 'Capitec'}, {id: 'FNB', label: 'First National Bank'}, {id: 'STD', label: 'Standard Bank'}, {id: 'ot', label: 'Other'}];
    const _employmentStatus = [{id: 'PE', label: 'Permanently employed'}, {id: 'SE', label: 'Self-employed'}, {id: 'CE', label: 'Contract'}];
    const _employmentIndustries = [
      {id: '1', label: 'Accountancy, banking and finance'},
       {id: '2', label: 'Business, consulting and management'},
        {id: '3', label: 'Engineering and manufacturing'},
         {id: '4', label: 'Environment and agriculture'},
         {id: '5', label: 'Healthcare'},
         {id: '6', label: 'Hospitality and events management'},
         {id: '7', label: 'Information technology'},
         {id: '8', label: 'Law'},
         {id: '9', label: 'Teacher training and education'},
         {id: '10', label: 'Transport and logistics'},
         {id: '11', label: 'Recruitment and HR'},
        ]

    const isValidInput = () => {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(email.match(mailformat) && luhn.validate(username)) {
            return true
        }

        return false;
    }

    const onUsernameChanged = (event) => {
        const idText = event.target.value;
        setUsername(idText)
        if(idText.length === 13 && luhn.validate(idText)) {
          setLoading(true)
          EKasiAPIClient.userExists(idText, (data) => {
             setLoading(false)
            if(data.code === Errors.accountNotFound) {
              setShowPassword(true);
            } else {
              showDialog(Strings.account, Strings.accountAlreadyExists, Strings.cancel, Strings.goToSignIn, "already_exists", "already_exists");
            }
          }, (res) => {
            setLoading(false)
            showDialog(Strings.account, res.data.msg, "", Strings.ok, null, null);
            setShowPassword(false);
          });
        } else {
            setShowPassword(false);
        }
    }

    const onPasswordChanged = (event) => {
        const password = event.target.value;
        setPassword(password);
        if(password.length >= 8) {
            setShowProceedButton(true);
        } else {
            setShowProceedButton(false);
        }
    }

    const handleClickShowPassword = () => {
      setPasswordVisibility(!visiblePassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);

    const _positiveHandler = () => {
      if(positiveHandler === "already_exists") {
        navigate(`/checkout/${id}/login`, { state: {loan: defaultData.loan }});
      }
    }

    const _negativeHandler = () => {
      if(negativeHandler === "already_exists") {
      }
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

  return (
    <>
          <EKasiLoader open={isLoading} text={Strings.verifyingIdNumber}/>
          <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={_negativeHandler} handlePositiveResponseButton={_positiveHandler} />
          <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{ isUserDashboard ? Strings.personalInformation :  Strings.getStarted}</Typography>
          <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => {
            if(!isValidInput() ){
              return
            }
            if(isUserDashboard) {
              const updatedCustomerData = { ...defaultData.customer, employer_name: employerName, employment_industry: employmentIndustry, employment_status: employmentStatus, bank_name: bank, salary_paid_in_account:  Number(Number(Globals.replaceAll(salary, ",", "")).toFixed(2)) }
              onSubmit(updatedCustomerData);
              return
            }
            onSubmit({account: {username, password, employerName, employmentIndustry, employmentStatus, firstname, lastName, email, bank, salary:  Number(Globals.replaceAll(salary, ",", "")).toFixed(2), phone }});
          })}>
            {
              !isUserDashboard ? 
              <Grid container spacing={3}>
                <FormInput defaultValue={defaultData?.account?.username} helperText={Strings.usernameHelper} onChange={onUsernameChanged} required name="username" label="Username"/>
                <Divider style={{margin: 20}} textAlign={!isMobile ? 'center' : 'left'}/>
              </Grid>
            : null
            }
            

          {
            showPassword ? 
            <>
              <Grid container spacing={3}>      
              <FormInput disabled={isUserDashboard} defaultValue={defaultData?.account?.firstname || defaultData?.customer?.name} onChange={(event) => setFirstName(event.target.value)} required name='firstname' label={Strings.firstname} />
              <Grid item xs={12} sm={6}>
              <PhoneInput
                ref={ref}
                disabled={isUserDashboard}
                inputComponent={PhoneInputField}
                error={!isValidPhone}
                defaultCountry="ZA"
                
                value={phone}
                onChange={async (inputNumber)  => {
                  try {
                    const phoneNumber = await parsePhoneNumber(inputNumber);
                    const isValid = await isValidPhoneNumber(phoneNumber.nationalNumber, 'ZA') === true;
                    setValidPhone(isValid)
                    setPhone(inputNumber)
  
                    } catch(error) {
                      setValidPhone(false)
                    }
                  }}
              />
              </Grid>
              <FormInput disabled={isUserDashboard} defaultValue={defaultData?.account?.lastName || defaultData?.customer?.last_name} onChange={(event) => setLastName(event.target.value)} required name='lastname' label={Strings.lastname} />
              <FormInput disabled={isUserDashboard} defaultValue={defaultData?.account?.email || defaultData?.customer?.email} onChange={(event) => setEmail(event.target.value)} required name='email' label={Strings.email} />
            </Grid>

            <Divider style={{margin: 20}} textAlign={!isMobile ? 'center' : 'left'}/>
            </> 
            : null
          }

          {
            showPassword ? 
            <>
            <Grid container spacing={3}>
            <FormInput defaultValue={defaultData?.account?.employerName || defaultData?.customer?.employer_name} onChange={(event) => setEmployerName(event.target.value)}  required name='employer' label={Strings.employerName} />
            <Grid item xs={12} sm={6}>
            <NumberFormat
                style={{padding:10}}
                fullWidth
                defaultValue={defaultData?.account?.salary || defaultData?.customer?.salary} 
                customInput={TextField}
                name='salary' 
                helperText={Strings.salaryHelper}
                label={Strings.salaryGrossIncome}
                isNumericString={true}
                thousandSeparator={true}
                value={salary}
                decimalScale={2}
                onValueChange={(vals) => setSalary(vals.formattedValue)}
                InputProps={{
                  startAdornment: <span>R</span>
                }}
              />
              </Grid>
            
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}> 
                  <InputLabel>{Strings.bankSalary}</InputLabel>
                  <Select required value={bank} fullWidth onChange={(e) => {
                      setBank(e.target.value);
                  }}>
                      {_banks.map((subdivision) => (
                              <MenuItem key={subdivision.id} value={subdivision.label}>
                                  {subdivision.label}
                              </MenuItem>
                      ))}
                      </Select>
                </Grid>
                <Grid item xs={6} sm={3}> 
                  <InputLabel>{Strings.employmentStatus}</InputLabel>
                  <Select required value={employmentStatus} fullWidth onChange={(e) => {
                      setEmploymentStatus(e.target.value);
                  }}>
                      {_employmentStatus.map((subdivision) => (
                              <MenuItem key={subdivision.id} value={subdivision.label}>
                                  {subdivision.label}
                              </MenuItem>
                      ))}
                      </Select>
                </Grid>
                <Grid item xs={6} sm={3}> 
                  <InputLabel>{Strings.employmentIndustry}</InputLabel>
                  <Select required value={employmentIndustry} fullWidth onChange={(e) => {
                      setEmploymentIndustry(e.target.value);
                  }}>
                      {_employmentIndustries.map((subdivision) => (
                              <MenuItem key={subdivision.id} value={subdivision.label}>
                                  {subdivision.label}
                              </MenuItem>
                      ))}
                      </Select>
                </Grid>
            </Grid>
            <Divider style={{margin: 20}} textAlign={!isMobile ? 'center' : 'left'}/>
            </> 
            : null
          }

          {
            showPassword && !isUserDashboard ?
            <Grid container spacing={3}>
             <FormInput
              required 
              name="password"
              label="Password"
             
             InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
             type={!visiblePassword ? "password" : "text"}
             helperText={Strings.passwordHelper}
             onChange={onPasswordChanged}
                 />
            </Grid>
            : null
            }
          {
          showPassword && !isUserDashboard ? 
              <FormGroup>
              <FormControlLabel style={{padding: 10}}  required control={<Checkbox defaultChecked={false} onChange={(e) => setReceivedSalaryInLastThreeMonths(e.target.checked)} />} label={Strings.salaryConfirmation} />
              <FormControlLabel style={{padding: 10}}  required control={<Checkbox defaultChecked={false} onChange={(e) => setNotUnderReviewAccepted(e.target.checked)} />} label={Strings.debtReviewConfirmation} />
                <FormControlLabel style={{padding: 10}}  required control={
                  <EKasiToolTip arrow={true}
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        <p>
                          <b>{Strings.readInfo}</b>
                        </p>
                        <p>
                          <a style={{color: Colours.white}} target={"_blank"} href="/terms">{Strings.termsOfUse}</a>
                        </p>
                        <p>
                          <a style={{color: Colours.white}} target={"_blank"} href="/privacy">{Strings.privacyPolicy}</a>
                        </p>
                      </Typography>
                    </React.Fragment>
                  }
                >
                <Checkbox defaultChecked={false} onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)} />
                </EKasiToolTip>
                } label={Strings.tcConfirmation} />
              </FormGroup>
          : null
          } 

          {
          (showProceedButton && privacyPolicyAccepted && receivedSalaryInLastThreeMonths && notUnderReviewAccepted) || isUserDashboard ? 
            <Button type="submit" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{isUserDashboard ? Strings.update : Strings.next}</Button>
          : null
          }
          </form>
          </FormProvider>
         
    </>
  );
}

export default RegistrationForm;

import React, {useState, useEffect} from 'react';
import { Box, Tab, Tabs, Container, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import Colours from '../../common/Colours';
import Strings from '../../localization/Strings';
import AddressForm from '../onboarding/AddressForm';
import { ImageWithText } from '../onboarding/Onboard';
import AffordabilityForm from '../onboarding/Affordability';
import useCheckMobile from '../../common/useCheckMobile';
import RegistrationForm from '../onboarding/RegistrationForm';
import moment from 'moment';
import EKasiDialog from '../../common/components/EKasiDialog';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import SignLoan from './SignLoan';
import { UploadFile } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function LoanBanner({loan}) {
  const isMobile = useCheckMobile()
  const navigate = useNavigate();

  const navigateToBankSubmission = (loan) => {
    let state = {
      minLoan: 200.00,
      maxLoan: 4000.00,
      minLoanTerm: 0,
      maxLoanTerm: 30,
      maxInitiationPercentage: 15,
      maxServiceFee: 60.00,

      amount: loan.amount,
      days: loan.term,
      interestRate: loan.interest_rate,
      interestPerDay: loan.interest_amount_per_day,
      initiationFee: loan.initiation_fee,
      totalRepayment: loan.total_repayment,
      serviceFee: loan.service_fee,
      fees: loan.fees,
      ...loan,
    }

    navigate('/onboard', { state: { loan: {...state} } });
  }

  const isMoreThan2WeeksOld = (loan) => {
    return moment().unix() >= moment.unix(loan.created_at).add(2, 'weeks').unix();
  }

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', backgroundColor: Colours.white, padding: 10, marginBottom: 10, borderRadius: 5}}>
      <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: Colours.white, padding: 10, marginBottom: 10, borderRadius: 5}}> 
      <ImageWithText title={Strings.applicationDate} amount={moment.unix(loan.created_at).format("DD/MM/YYYY")}/>
      {
        !isMoreThan2WeeksOld(loan) && (loan.truid_collection_id === "" || loan.status === "VERIFICATION" )?
        <Button onClick={(e) => navigateToBankSubmission(loan)} disableElevation variant="contained" endIcon={<UploadFile />}>{Strings.bankStatementHelper.replace(".","")}</Button>
        : null
      }
      </Container>
      <ImageWithText title={loan.status.replace("_", " ")} amount={""}/>
      <Container style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', backgroundColor: Colours.white, padding: 10, marginBottom: 10, borderRadius: 5}}>
          <ImageWithText title={Strings.creditAgreement} amount={loan._id}/>
          <ImageWithText title={Strings.eKasiCredit} amount={`R ${loan.amount.toFixed(2)}`}/>
          <ImageWithText title={Strings.term} amount={`${loan.term_in_days} ${Strings.days}`}/>
          <ImageWithText title={Strings.totalRepayment} amount={`R ${loan.total_repayment.toFixed(2)}`}/>
      </Container>
    </Container>
  )
}
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 30, margin: 10}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const Dashboard = ({ userData }) => {
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState(userData);
    const [approvedLoan, setApprovedLoans] = React.useState(null);

    useEffect(() => {
      setData(userData);
      getLatestLoanInApproved()
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLatestLoanInApproved = () => {
      const approvedLoans = userData.loans.filter((loan) => {
        return loan.status === "APPROVED";
      });

      // Descending
      const newSortedLoans = approvedLoans.sort((loan1, loan2) => { return Number(loan1.created_at) - Number(loan2.created_at) });
      if(newSortedLoans.length > 0) {
        setApprovedLoans(newSortedLoans[0]);
      }
    }

    const setSignedComplete = () => {
      setApprovedLoans(null);
      setValue(0)
    }

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleBack = () => {

    }

    const onSubmit= (customerUpdatedData) => {
      EKasiAPIClient.updateCustomer(data.token, customerUpdatedData, (res) => {
        const updatedData = { ...data, customer: {...customerUpdatedData}};
        setData(updatedData);
        showDialog(Strings.infoUpdate, Strings.customerDataUpdateSuccess, "", Strings.ok, null, null);
      }, (error) => {
        showDialog(error.code, error.data.msg, "", Strings.ok, null, null);
      });
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    return (
        <>
          <Helmet>
              <title> Get me credit | My dashboard </title>
          </Helmet>

          <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
          <Box sx={{ backgroundColor: Colours.white, borderTop: 1, borderBottom: 1, borderColor: 'divider' }}>
            
              {
                approvedLoan !== null ?
                <Tabs value={value} 
                onChange={handleChange} 
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="Dashboard items">
                  <Tab style={{fontWeight: 'bolder', color: Colours.white, backgroundColor: Colours.viridianGreen}} label={Strings.loanContract} {...a11yProps(0)} />
                  <Tab label={Strings.myLoans} {...a11yProps(1)} />
                  <Tab label={Strings.myPersonalInfo} {...a11yProps(2)} />
                  <Tab label={Strings.myAddress} {...a11yProps(3)} />
                  <Tab label={Strings.myFinancials} {...a11yProps(4)} />
                </Tabs>  
                :
                <Tabs value={value} 
                onChange={handleChange} 
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="Dashboard items">
                  <Tab label={Strings.myLoans} {...a11yProps(0)} />
                  <Tab label={Strings.myPersonalInfo} {...a11yProps(1)} />
                  <Tab label={Strings.myAddress} {...a11yProps(2)} />
                  <Tab label={Strings.myFinancials} {...a11yProps(3)} />
              </Tabs>  
              }
          </Box>

          {
            approvedLoan !== null ?
            <>
             <TabPanel key={"0"} value={value} index={0}>
               <SignLoan setSignedComplete={setSignedComplete} loan={approvedLoan} token={userData.token}/>
             </TabPanel>
             <TabPanel key={"1"} value={value} index={1}>
                {data.loans.map((loan) => <LoanBanner key={loan._id} loan={loan} />)}
            </TabPanel>
            <TabPanel key={"2"} value={value} index={2}>
              <RegistrationForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data} />
            </TabPanel>
            <TabPanel key={"3"} value={value} index={3}>
              {/* {Strings.myAddress} */}
                <AddressForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data}/>
            </TabPanel>
            <TabPanel key={"4"} value={value} index={4}>
              <AffordabilityForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data}/>
            </TabPanel>
            </>
           : 
           <>
           <TabPanel key={"0"} value={value} index={0}>
                {data.loans.sort((a,b) => Number(b.created_at) - Number(a.created_at)).map((loan) => <LoanBanner key={loan._id} loan={loan} />)}
            </TabPanel>
            <TabPanel key={"1"} value={value} index={1}>
              <RegistrationForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data} />
            </TabPanel>
            <TabPanel key={"2"} value={value} index={2}>
              {/* {Strings.myAddress} */}
                <AddressForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data}/>
            </TabPanel>
            <TabPanel key={"3"} value={value} index={3}>
              <AffordabilityForm onPrev={handleBack} onSubmit={onSubmit} defaultData={data}/>
            </TabPanel>
            </>
          }
         
         
        </>
    )
}

export default Dashboard;
import { Button, FormControlLabel, Grid, Typography, Checkbox, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Colours from '../../common/Colours';
import EKasiDialog from '../../common/components/EKasiDialog';
import useCheckMobile from '../../common/useCheckMobile';
import Strings from '../../localization/Strings';
import EKasiAPIClient from '../../network/EkasiAPIClient';

export const Review = ({onPrev,  onSubmit, defaultData}) => {
    let isMobile = useCheckMobile();
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") 
    const [showProceedButton, setShowProceedButton] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();

    const ColonSeparatedText = ({title, subtitle}) => {
        return (
            <Grid item xs={12} sm={6}>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} >
                <Typography style={{ fontWeight: '100',color: Colours.viridianGreen}}>{title}:</Typography>
                <Typography style={{ fontWeight: 'bold', color: Colours.viridianGreen}}>{subtitle}</Typography>
            </Box>
            </Grid>
        );
    }

    const processNewApplication = () => {
        setLoading(true);
        EKasiAPIClient.newApplication(defaultData, location.pathname.includes("checkout"), (data) => {
            setLoading(false);
            onSubmit({loan_id: data.data.loan_id, token: data.data.token});
        }, (error) => {
            setLoading(false);
            showDialog(error.code, `${error.data.msg}`, "", Strings.ok, null, null);
        });
    }

    const processApplication = () => {
        setLoading(true);
        EKasiAPIClient.application(defaultData, location.pathname.includes("checkout"), (data) => {
            setLoading(false);
            onSubmit({loan_id: data.data.loan_id, token: defaultData.token});
        }, (error) => {
            setLoading(false);
            showDialog(error.code, `${error.data.msg}`, "", Strings.ok, null, null);
        });
    }

    const onProcessApplication = () => {
        if(isUserDashboard) {
            processApplication();
        } else {
            processNewApplication();
        }

    }

    const onDeclaractionCheck = (event) => {
        setShowProceedButton(event.target.checked)
    }

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);


    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
    }

    return (
        <>
        <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{Strings.reviewApplication}</Typography>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.personalInformation}</Typography>
        <Grid container spacing={3}>
            {
                !isUserDashboard ?
                <ColonSeparatedText title={Strings.idNumber} subtitle={defaultData?.account?.username}/>
                : null
            }
            <ColonSeparatedText title={Strings.firstname} subtitle={defaultData?.account?.firstname || defaultData?.customer?.name}/>
            <ColonSeparatedText title={Strings.lastname} subtitle={defaultData?.account?.lastName || defaultData?.customer?.last_name}/>
            <ColonSeparatedText title={Strings.address} subtitle={`${defaultData?.address?.address1 ||  defaultData.customer.address_1}, ${defaultData?.address?.city || defaultData?.customer?.city}, ${defaultData?.address?.province || defaultData?.customer?.province}, ${defaultData?.address?.zip ||  defaultData?.customer?.postal_code}, ${defaultData?.address?.country || defaultData?.customer?.country}`}/>
        </Grid>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.contactInformation}</Typography>
        <Grid container spacing={3}>   
            <ColonSeparatedText title={Strings.phoneNumber} subtitle={defaultData?.account?.phone || defaultData?.customer?.phone_number}/>
            <ColonSeparatedText title={Strings.email} subtitle={defaultData?.account?.email || defaultData?.customer?.email}/>

        </Grid>

        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.employmentInformation}</Typography>
        <Grid container spacing={3}>   
            <ColonSeparatedText title={Strings.employerName} subtitle={defaultData?.account?.employerName || defaultData?.customer?.employer_name}/>
            <ColonSeparatedText title={Strings.employmentIndustry} subtitle={defaultData?.account?.employmentIndustry || defaultData?.customer?.employment_industry}/>
            <ColonSeparatedText title={Strings.employerName} subtitle={defaultData?.account?.employmentStatus || defaultData?.customer?.employment_status}/>
            <ColonSeparatedText title={Strings.salaryGrossIncome} subtitle={`R ${Number(defaultData?.account?.salary || defaultData?.customer?.salary_paid_in_account).toFixed(2)}`}/>
        </Grid>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.financialInformation}</Typography>
        <Grid container spacing={3}>   
            <ColonSeparatedText title={Strings.netIncome} subtitle={`R ${defaultData?.affordability?.netIncome || defaultData?.customer?.affordability?.net_income}`} />
            <ColonSeparatedText title={Strings.accomodationExpenses} subtitle={`R ${defaultData?.affordability?.accomodation || defaultData?.customer?.affordability?.accomodation}`} />
            <ColonSeparatedText title={Strings.waterElectricity} subtitle={`R ${defaultData?.affordability?.waterElectricity || defaultData?.customer?.affordability?.water_electricity}`} />
            <ColonSeparatedText title={Strings.foodExpenses} subtitle={`R ${defaultData?.affordability?.food || defaultData?.customer?.affordability?.food}`} />
            <ColonSeparatedText title={Strings.transportExpenses} subtitle={`R ${defaultData?.affordability?.transport || defaultData?.customer?.affordability?.transport}`} />
            <ColonSeparatedText title={Strings.maintainence} subtitle={`R ${defaultData?.affordability?.maintainence || defaultData?.customer?.affordability?.maintainence}`} />
            <ColonSeparatedText title={Strings.creditCard} subtitle={`R ${defaultData?.affordability?.creditCard || defaultData?.customer?.affordability?.credit_card}`} />
            <ColonSeparatedText title={Strings.personalLoan} subtitle={`R ${defaultData?.affordability?.personalLoan || defaultData?.customer?.affordability?.personal_loan}`} />
        </Grid>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{!location.pathname.includes("checkout")  ? Strings.loanInformation : Strings.checkoutInformation}</Typography>
        <Grid container spacing={3}>   
            <ColonSeparatedText title={Strings.creditAmount} subtitle={`R ${Number(defaultData.loan.amount).toFixed(2)}`}/>
            <ColonSeparatedText title={Strings.creditTerm} subtitle={!location.pathname.includes("checkout") ? `${defaultData.loan.days} ${Strings.days}` : `${defaultData.loan.days} ${Strings.monthlyInstalments}`}/>
            <ColonSeparatedText title={Strings.creditInitiation} subtitle={`R ${Number(defaultData.loan.initiationFee).toFixed(2)}`}/>
            <ColonSeparatedText title={Strings.creditServiceFee} subtitle={`R ${Number(defaultData.loan.serviceFee).toFixed(2)}`}/>
            {
            !location.pathname.includes("checkout") ?
                <ColonSeparatedText title={Strings.totalRepayment} subtitle={`R ${Number(defaultData.loan.totalRepayment).toFixed(2)}`}/>
            : null

            }
        </Grid>

        <FormControlLabel style={{padding: 10}}  required control={<Checkbox defaultChecked={false} onChange={onDeclaractionCheck} />} label={Strings.declaration} />
          {
                isLoading ? 
                <Box style={{ display: 'flex', flex: 1, justifyContent: 'center',}}>
                    <CircularProgress variant="indeterminate" />
                </Box>
                :
                <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',}}>
                    {
                        !isUserDashboard ? 
                        <Button onClick={onPrev} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.back}</Button>
                        : 
                        <Button component={Link} to="/dashboard" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.cancel}</Button>
                    }
                    {
                
                    showProceedButton ? 
                        <Button onClick={onProcessApplication} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.processApplication}</Button>
                    : null
                    }
                </Box>
            }
         
        </>
    );


}

export default Review;
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import LocalizedStrings from "../../localization/Strings";
import Colours from "../../common/Colours";
import { useEffect } from "react";
import { useState } from "react";
import EKasiAPIClient from "../../network/EkasiAPIClient";
import EKasiLoader from "../../common/components/EKasiLoader";
import EKasiDialog from "../../common/components/EKasiDialog";
import { ArticleCard } from "./ArticleCard";
import { useNavigate } from "react-router-dom";

export const ArticlesList = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [articles, setArticles] = useState([])

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState("");
    const [, setPositiveHandler] = useState("");
    const [openDialog, setOpenDialog] = useState(false);



    useEffect(() => {
        getArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const positiveClickHandler = (event) => {
        setPositiveHandler("");
    }


    const negativeClickHandler = (event) => {
        if (negativeHandler === "back") {
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/'); // the current entry in the history stack will be replaced with the new one with { replace: true }
            }
        }
        setNegativeHandler("");
    }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
        setTitle(title);
        setMessage(message);
        setNegativeTitle(negativeTitle);
        setNegativeHandler(handleNegativeResponseButton);
        setPositiveTitle(positiveTitle);
        setPositiveHandler(handlePositiveResponseButton);
        setOpenDialog(true);
    }

    const getArticles = () => {
        setLoading(true);
        EKasiAPIClient.getAllArticles((res) => {
            setLoading(false);
            setArticles(res.data.articles)
        }, (error) => {
            setLoading(false);
            showDialog(error.data.code, error.data.data.msg, LocalizedStrings.back, "", "back", null);
        });
    }

    return (
        <>
        <Helmet>
            <title>{LocalizedStrings.myFinancialWellbeing}</title>
            <meta name="description" content="Get help with your finances online" />
        </Helmet>
        <EKasiLoader open={isLoading}/>
        <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeClickHandler} handlePositiveResponseButton={positiveClickHandler} />
        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 40, marginTop: 10, marginBottom: 10}}>
        <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${LocalizedStrings.eKasiCredit} | `}
                <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                    {LocalizedStrings.myFinancialWellbeing}            
                </Typography> 
            </Typography>

            <Box display="flex" flexWrap="wrap" width="100%" gap={1}>
            {articles.map(article => (
                <ArticleCard key={article.id} article={article} />
            ))}
        </Box>
        </Box>

       
        
        </>
    )
}

export default ArticlesList;
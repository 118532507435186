import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Colours from '../../common/Colours';
import FormInput from '../../common/FormInput';
import useCheckMobile from '../../common/useCheckMobile';
import Strings from '../../localization/Strings';
import EKasiAPIClient from '../../network/EkasiAPIClient';
import Globals from '../../common/Globals';
import EKasiDialog from '../../common/components/EKasiDialog';
import EKasiLoader from '../../common/components/EKasiLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import EKasiToolTip from '../../common/components/EKasiToolTip';

export const BankStatement = ({ status, setRedirect, onSubmit, defaultData }) => {
    let navigate = useNavigate();
    const [showProceedButton, setShowProceed] = useState(false);
    const isMobile = useCheckMobile();
    const methods = useForm();
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined");
    const location = useLocation();

    const [statement, setStatement] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      if(status) {
        showDialog(Strings.truIDService, Strings.truidIncompleteError, "", "Ok", null, null);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateLoanStatus = (status) => {
      let id  =  defaultData.loan_id || defaultData.loan._id;
      setLoading(true)
      EKasiAPIClient.updateLoanStatus(defaultData.token, id, status, location.pathname.includes("checkout"), (response) => {
        setLoading(false)
        onSubmit({})
      }, (error) => {
        setLoading(false)
        onSubmit({})
      });
    }

    const submitDocumentToAPI = () =>  {
      // TODO: This should already put loan application in verification
        setLoading(true)
        let id  =  defaultData.loan_id || defaultData.loan._id;
        if (statement != null) {
          EKasiAPIClient.upload(statement, Globals.incomeBucket, defaultData.token, id, 
          (resData1) => {
            if(!isUserDashboard) {
              EKasiAPIClient.upload(defaultData.id_document, Globals.idBucket, defaultData.token, id, 
                (resData2) => {
                  setLoading(false)
                  updateLoanStatus("VERIFICATION");
                }, (error) => {
                  setLoading(false)
                  showDialog(Strings.documentUpload, error.data.msg, "", Strings.ok, null, null);
                });
            } else {  
              setLoading(false)
              updateLoanStatus("VERIFICATION");
            }
          }, (error) => {
            setLoading(false)
            showDialog(Strings.documentUpload, error.data.msg, "", Strings.ok, null, null);
          });
        }
    }

    const submitLaterClick = () => {
      navigate('/dashboard');
    }

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [negativeTitle, setNegativeTitle] = useState("");
    const [positiveTitle, setPositiveTitle] = useState("");

    const [negativeHandler, setNegativeHandler] = useState(null);
    const [positiveHandler, setPositiveHandler] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    const goToTruId = () => {
      setLoading(true)
      let id  =  defaultData.loan_id || defaultData.loan._id;
      EKasiAPIClient.getTruIdTransactionHistory(defaultData.token, id, location.pathname.includes('/checkout/'), (response) => {
        setLoading(false);
        const {collection_url} = response.data;
        setRedirect(collection_url)
        navigate("/redirect-truid", {collection_url});
      }, (error) => {
        setLoading(false);
          showDialog(Strings.truIDService, error.data.msg, "", Strings.ok, null, null);
      });
    }

    return (
        <>
        <EKasiLoader open={isLoading}/>
        <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeHandler} handlePositiveResponseButton={positiveHandler} />
        <Typography style={{ fontWeight: 'bold',color: Colours.variantViridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{ !location.pathname.includes("checkout") ? Strings.loanApprovedPending :  Strings.checkoutApprovedPending }</Typography>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.uploadUsingTrueId}</Typography>
          <EKasiToolTip arrow={true}
            title={
              <React.Fragment>
                <Typography color="inherit">What is truID?</Typography>
                <b>TruID</b> is a secure service that allows us to retrieve your <b>bank statement</b>. To find out more about the service visit <a href="https://www.truid.co.za">https://www.truid.co.za</a>                
              </React.Fragment>
            }
          >
            <Button onClick={goToTruId} style={{ backgroundColor: Colours.truidPrimary, padding: 10, marginLeft: 7, maxWidth: '50%' }} fullWidth disableElevation variant="contained">{Strings.uploadUsingTrueId}</Button>
          </EKasiToolTip>
        
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 20 }} >{Strings.or}</Typography>

        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{Strings.documentUpload}</Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => {
            submitDocumentToAPI()
          })}>
            <Grid container spacing={3}>
              <FormInput type={"file"} inputProps={{ accept: 'image/*, application/pdf' }} helperText={Strings.uploadStatement} onChange={(event) => {  
                if (event.target.files !== null && event.target?.files?.length > 0) {
                  setShowProceed(true);
                  setStatement(event.target.files[0]);
                } else {
                  setShowProceed(false);
                }
            }} 
            name="bank-statement" />
            </Grid>
            {
              location.pathname.includes('/checkout/') ?
              null :
                <Grid item xs={12} sm={6}>
                    <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} >{`${Strings.uploadAlternativelyEmail}`.replace('%', Strings.documentUploadEmail).replace('%', defaultData.loan_id)}</Typography>
                </Grid>  
              } 
                              
                <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',}}>
                  {
                    location.pathname.includes('/checkout/') ?
                    null :
                    <Button onClick={submitLaterClick} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.submitLater}</Button>

                  }
                  {
                  showProceedButton ? 
                  <Button type="submit" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.submitDocuments}</Button>
                  : null
                  }
                </Box>
          </form>
          </FormProvider>


        </>
    )
}

export default BankStatement;
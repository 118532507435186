import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LoginIcon from '@mui/icons-material/Login';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import { Link, useNavigate } from 'react-router-dom';
import { ImageListItem, ListItemIcon } from '@mui/material';
import { Logout } from '@mui/icons-material';
import Logo from '../../assets/images/logo.png';

const pages = ['Apply now', 'For Merchants', 'Contact support', 'About us', 'FAQs', `| ${Strings.myFinancialWellbeing}`];
// const settings = ['Dashboard', 'Profile', 'Account', 'Loans', 'Logout'];

const Navbar = ({ isAuthenticated, name, clearLocalCache, getCustomerData, token, showNavBar }) => {
  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);


    if(page === pages[0]) {
      navigate('/application')
    }  else if(page === pages[1]) {
      navigate('/merchant_services')
    } else if(page === pages[2]) {
      navigate('/support')
    } else if(page === pages[3]) {
      navigate('/about')
    } else if(page === pages[4]) {
      navigate('/faqs')
    } else if(page === pages[5]) {
      navigate('/financial_wellness')
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickedUserMenu = (page) => {
    setAnchorElUser(null);

    if(page === "dashboard") {
      if(isAuthenticated) {
        getCustomerData(token, (response) => {
          navigate('/dashboard')
        }, (error) => {
          navigate('/dashboard')
        });
      }
    } else {
      clearLocalCache();
      navigate('/');
    } 
  };

  const LoggedInMenu = ({anchorElUser , handleOpenUserMenu, handleCloseUserMenu}) => (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Typography style={{color: Colours.viridianGreen}} sx={{ minWidth: 100 }}>{Strings.hi} {name}</Typography>
        <Tooltip title={Strings.myDashboard}>
          <IconButton
            onClick={handleOpenUserMenu}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={Boolean(anchorElUser) ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorElUser) ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, backgroundColor: Colours.variantViridianGreen }}>{name.charAt(0)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorElUser}
        id="account-menu"
        open={Boolean(anchorElUser) }
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(e) => handleClickedUserMenu("dashboard")} style={{color: Colours.viridianGreen}}>
          <Avatar style={{background: Colours.viridianGreen}}/> 
          {Strings.myDashboard}
        </MenuItem>
        <MenuItem onClick={(e) => handleClickedUserMenu("")}>
          <ListItemIcon>
            <Logout style={{color: Colours.viridianGreen}} fontSize="small" />
          </ListItemIcon>
         {Strings.logout}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );

  if (!showNavBar) {
    return null;
  }

  return (
    <AppBar elevation={0} style={{backgroundColor: Colours.white }} position="static">
      <Container style={{backgroundColor: Colours.white}} maxWidth="xl">
        <Toolbar disableGutters>
          <ImageListItem key={"1"} style={{color: Colours.viridianGreen}} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src={Logo} alt="logo"/>
          </ImageListItem>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            style={{color: Colours.viridianGreen}}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
           {Strings.ekasiLoans}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{color: Colours.viridianGreen}} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page,idx) => {
                if(!isAuthenticated && page === pages[0]) {
                  return null
                }
               return (
                  <MenuItem key={page} onClick={(e) => handleCloseNavMenu(page)}>
                    <Typography style={{color: Colours.viridianGreen}} textAlign="center">{page === pages[5] ? page.substring(2) : page}</Typography>
                  </MenuItem>
                ) 
              })}
            </Menu>
          </Box>
          <ImageListItem 
           component={Link}
           to="/"
           key={"2"} 
           style={{color: Colours.viridianGreen}}
           sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img 
            src={Logo} 
            alt="logo"/>
          </ImageListItem>
          {/* <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            style={{color: Colours.viridianGreen}}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => {
              if(!isAuthenticated && page === pages[0]) {
                return null
              }

              return (<Button
                style={{color: Colours.viridianGreen}}
                key={page}
                onClick={(e) => handleCloseNavMenu(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>)
            })}
          </Box>
          {
              !isAuthenticated ? <Button onClick={(e) =>  navigate('/login')} disableElevation variant="contained" endIcon={<LoginIcon />}>{Strings.login}</Button> :
              
              <LoggedInMenu anchorElUser={anchorElUser} handleOpenUserMenu={handleOpenUserMenu} handleCloseUserMenu={handleCloseUserMenu} />
          }

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;

import React from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import { Typography } from "@mui/material";
import Localized from '../../localization/Strings';
import { SubheadingWithBody } from "./Privacy";

export const Terms = () => {
    return (
        <>
        <Helmet>
            <title>Terms Of Use </title>
        </Helmet>

        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
            <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${Localized.eKasiCredit} | `}
                <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                    {Localized.termsOfUse}            
                </Typography> 
            </Typography>
            <SubheadingWithBody heading={Localized.agreement} body={Localized.agreementBody} />
            <SubheadingWithBody heading={Localized.intellectaulProperty} body={Localized.intellectaulPropertyBody} />
            <SubheadingWithBody heading={Localized.creditConsentTitle} body={Localized.creditConsent} />
        </Box>
        </>
    )
}

export default Terms;
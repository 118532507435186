import React, { useEffect } from 'react';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import Helmet from 'react-helmet';
import { Box } from '@mui/system';
import { Container, Typography, Stepper, Step, StepButton, Divider, Button, } from '@mui/material';
import { AccountBalance, ArrowBack, Autorenew, CalendarMonth, CardMembership, Close, CurrencyExchange, Payments, PendingActions, Security, VerifiedUser } from '@mui/icons-material';
import RegistrationForm from './RegistrationForm';
import useCheckMobile from '../../common/useCheckMobile';
import AddressForm from './AddressForm';
import AffordabilityForm from './Affordability';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Review from './Review';
import BankStatement from './BankStatement';
import { IdentityVerification } from './IdentityVerification';
import Approved from './Approved';
import EKasiLoader from '../../common/components/EKasiLoader';
import EKasiDialog from '../../common/components/EKasiDialog';
import moment from 'moment/moment';
import Globals from '../../common/Globals';
import { OTP } from './OTP';
import { useRef } from 'react';

export function TitleImage({title})  {
  if(title === Strings.eKasiCredit) {
      return <CurrencyExchange fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === Strings.repayIn || title === Strings.term) {
      return <CalendarMonth fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === Strings.totalRepayment) {
    return <Payments fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === Strings.instalments) {
    return <Payments fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === Strings.creditAgreement) {
    return <CardMembership fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === "PENDING") {
    return <Autorenew fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === "VERIFICATION") {
    return <Security fontSize='inherit' style={{color: Colours.backgroundGrey}}/>
  } else if(title === "APPROVED") {
    return <VerifiedUser fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === "DECLINED") {
    return <Close fontSize='inherit' style={{color: Colours.red}}/>
  } else if(title === "PENDING_PAYMENT") {
    return <AccountBalance fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  }else if(title === "PENDING_DEBIT_CHECK") {
    return <PendingActions fontSize='inherit' style={{color: Colours.viridianGreen}}/>
  } else if(title === Strings.applicationDate) {
    return null;
  }

  return <CurrencyExchange fontSize='inherit' style={{color: Colours.viridianGreen}}/>
}

export function ImageWithText({ title, amount, estimated }) {

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', margin: 10}} >
        <TitleImage title={title}/>
        <Box>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, paddingLeft: 10}}  textAlign="center">{title}</Typography>
        <Typography style={{ fontWeight: 'bold', fontSize: 10, color: Colours.viridianGreen }}  textAlign="center">{amount}</Typography>
        </Box>
        {
          (typeof estimated !== "undefined") && estimated ?
          <Typography style={{ fontWeight: '100', fontSize: 10, color: Colours.viridianGreen }}  textAlign="center">*estimated</Typography>
          : null
        }
    </Box>
  )
} 
const Onboard = ({ setRedirect, userData, getCustomerData }) => {
    const didMount = useRef(null)
    const checkMobile = useCheckMobile();
    const navigate = useNavigate();
    const steps = [Strings.newApplication, Strings.address, Strings.affordability, Strings.OTP, Strings.identityVerification, Strings.review, Strings.incomeVerification, Strings.approvedTitle];
    const isUserDashboard =  (typeof userData?.customer !== "undefined") 
    const loanDetails = useLocation();

    const [loaderText, setLoaderText] = React.useState(Strings.verifyingInfo);
    const [isLoading, setLoading] = React.useState(false);

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [applicationData, setApplicationData] = React.useState({loan: {...loanDetails.state.loan}, ...userData});

    useEffect(() => {
      if (!didMount.current) {
        didMount.current = true
        if(!loanDetails.state.status && (isUserDashboard && (applicationData.loan.status === "VERIFICATION" || applicationData.loan.truid_collection_id === "") && applicationData.loan.loan_applicants_image_base64 !== "")){
          setActiveStep(6)
          setCompleted({0: true, 1: true, 2: true, 3: true, 4: true, 5: true});
        } else if(isUserDashboard && !loanDetails.state.status) {
          setActiveStep(3)
          setCompleted({0: true, 1: true, 2: true});
        }  else if((isUserDashboard && (loanDetails.state.status === "2000" || loanDetails.state.status === "0"))) {
          setActiveStep(7)
          setCompleted({0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true});
        } else if((isUserDashboard && loanDetails.state.status !== "2000")) {
          setActiveStep(7)
          setCompleted({0: true, 1: true, 2: true, 3: true, 4: true, 5: true });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    // const handleStep = (step) => () => {
    //   setActiveStep(step);
    // };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    // const handleReset = () => {
    //   setActiveStep(0);
    //   setCompleted({});
    // };

    const RepaymentColumn = ({amount, month}) => (
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 5 }}>
          <Typography style={{ fontSize: 13, color: Colours.viridianGreen, fontWeight: 'bold'}}  textAlign="center">R {amount.toFixed(2)}</Typography>
          <Typography style={{ fontSize: 10, color: Colours.viridianGreen, fontWeight: '100'}}  textAlign="center">{moment().add(month, 'month').format('MMMM')}</Typography>
          <Typography style={{ fontSize: 9, color: Colours.viridianGreen, fontWeight: '100'}}  textAlign="center">{Strings.estimated}</Typography>
      </Box>
  );

    const CheckoutRepayments = ({title}) => (
      <Box key={`box-${1}`} style={{ display: 'flex', flexDirection: 'column', margin: 10}} >
      <Box key={`box-${2}`}  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <TitleImage key={`box-${3}`} title={title}/>
        <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, paddingLeft: 10}}  textAlign="center">{title}</Typography>
      </Box>

      <Box key={`box-${4}`}  style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
          {
              applicationData.loan.interestRate === 0 ?
              <>
                <Box key={`box-${5}`} style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 5 }}>
                  <Typography style={{ fontSize: 13, color: Colours.viridianGreen, fontWeight: 'bold'}}  textAlign="center">R {(applicationData.loan.amount * Globals.layByDepositPercent / 100.00).toFixed(2)}</Typography>
                  <Typography style={{ fontSize: 10, color: Colours.viridianGreen, fontWeight: '100'}}  textAlign="center">{Strings.thirtyPercentDeposit}</Typography>
              </Box>
              <Divider key={`divider-main`} orientation='vertical'/>
              </> : null
          }
          {
              applicationData.loan.totalRepayment.map((amount, index) => (
                  <>
                  <RepaymentColumn key={`repayments-onboard-${index}`} amount={amount} month={index + 1}/>
                  {
                      (((index + 1) % applicationData.loan.days) >= 1) ? 
                      <Divider key={`repayments-onboard-divider-${index}`} orientation='vertical'/> : null
                  }
                  </>
              ))
          } 
        </Box>
      </Box>
    )
    
    const LoanBanner = ({amount, days, totalRepayment}) => (
        <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: Colours.white, padding: 0}}>
            <ImageWithText key={`i-${1}`} title={loanDetails.pathname.includes('checkout')  ? Strings.checkoutCreditAmount : Strings.creditAmount} amount={`R ${amount.toFixed(2)}`}/>
            <ImageWithText key={`i-${2}`}  title={Strings.repayIn} amount={`${days} ${loanDetails.pathname.includes('checkout') ? Strings.monthlyInstalments : Strings.days}`}/>
            {
              loanDetails.pathname.includes('checkout') && !checkMobile  ?
              <ImageWithText key={`i-${3}`}  title={applicationData.loan.merchant_name} amount={`Checkout - ${applicationData.loan.merchant_chkt_id}`}/>
              : null
            }
            {
              loanDetails.pathname.includes('checkout') && !checkMobile ?
              <CheckoutRepayments key={`i-${4}`}  title={Strings.instalments}/>
              : null
            }
            {
              !loanDetails.pathname.includes('checkout') ?
              <ImageWithText estimated={true} key={`i-${5}`} title={Strings.totalRepayment} amount={`R ${totalRepayment.toFixed(2)}`}/>
              : null
            }
        </Container>
    )

    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [negativeTitle, setNegativeTitle] = React.useState("");
    const [positiveTitle, setPositiveTitle] = React.useState("");
    // eslint-disable-next-line no-unused-vars
    const [negativeHandler, setNegativeHandler] = React.useState("");
     // eslint-disable-next-line no-unused-vars
    const [positiveHandler, setPositiveHandler] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);

    const negativeAction = (e) => {
      
    }

      const positiveAction = (e) => {
        fetchCustomerData()
      }

    const showDialog = (title, message, negativeTitle, positiveTitle, handleNegativeResponseButton, handlePositiveResponseButton) => {
      setTitle(title);
      setMessage(message);
      setNegativeTitle(negativeTitle);
      setNegativeHandler(handleNegativeResponseButton);
      setPositiveTitle(positiveTitle);
      setPositiveHandler(handlePositiveResponseButton);
      setOpenDialog(true);
    }

    const onSubmit = (data) => {
      setApplicationData({
        ...applicationData,
        ...data
      });
      handleComplete();
      if(activeStep === 7) {
        fetchCustomerData();
      }
    }

    const fetchCustomerData = () => {
      setLoaderText(Strings.initializingLoanContract)
        setLoading(true);
        getCustomerData(userData.token, (response) => {
          setLoading(false);
          navigate('/dashboard');
        }, (error) => {
          setLoading(false);
          showDialog(error.code, error.data.msg, Strings.cancel, Strings.retry, null, null);
        });
    }

    const getForm = (label) => {
      if(label === 0) {
        return(
          <RegistrationForm onSubmit={onSubmit} defaultData={applicationData}/>
        )
      } else if(label === 1) {
        return(
          <AddressForm onPrev={handleBack} onSubmit={onSubmit} defaultData={applicationData}/>
        )
      } else if(label === 2) {
        return(
          <AffordabilityForm onPrev={handleBack} onSubmit={onSubmit} defaultData={applicationData}/>
        )
      } else if(label === 3) {
        return (<OTP onPrev={handleBack} onSubmit={onSubmit} defaultData={applicationData}/>)
      } else if(label === 4) {
        return <IdentityVerification onPrev={handleBack} onSubmit={onSubmit} defaultData={applicationData}/>
      } else if(label === 5) {
        return <Review onPrev={handleBack} onSubmit={onSubmit} defaultData={applicationData}/>
      }  else if(label === 6) {
        return <BankStatement status={loanDetails.state.status} setRedirect={setRedirect} onSubmit={onSubmit} defaultData={applicationData}/>
      }

      return(
        <Approved onSubmit={onSubmit} status={loanDetails.state.status} getCustomerData={getCustomerData} defaultData={applicationData} />
      )
    }

    const OnboardingStep = () => (
        <Box sx={{ width: '100%', paddingTop: '30px' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit">
                    {!checkMobile ? label : null}
                    </StepButton>
                </Step>
                ))}
            </Stepper>
      </Box>
    )

    return(
        <>
            <Helmet>
                <title> Loan online application </title>
                <meta name="description" content="Cash loans" />
            </Helmet>
            <EKasiLoader open={isLoading} text={loaderText} />
            <EKasiDialog open={openDialog} handleOpen={setOpenDialog} message={message} title={title} negativeTitle={negativeTitle} positiveTitle={positiveTitle} handleNegativeResponseButton={negativeAction} handlePositiveResponseButton={positiveAction} />

            <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 30, marginTop: 10,  marginBottom: 10}}>
           
           <Box>
            {
               loanDetails.pathname.includes('checkout') ?
               <Button   
               component={Link}
               to={applicationData.loan.merchant_redirect_url}
               disableElevation variant="contained" 
               startIcon={<ArrowBack />}>
                 {Strings.goBackTo}{applicationData.loan.merchant_name}
               </Button> 
               : null
            }
          
           </Box>
             {!isLastStep() ? <LoanBanner amount={Number(applicationData.loan.amount) || applicationData.loan.amount} days={applicationData.loan.days || applicationData.loan.term_in_days} totalRepayment={Number(applicationData.loan.totalRepayment) || applicationData.loan.total_repayment} /> : null }
                <OnboardingStep />
                {getForm(activeStep)}
            </Box>
        </>
    )
}

export default Onboard;
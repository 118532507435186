export default class Colours {
    static viridianGreen = '#009698';
    static brown = "#982600";
    static variantViridianGreen = "#197d7f";
    static truidPrimary = "#03092C";
    static green = "#03C04A";
    static maroon = "#7f194a";
    static red = "#FF0000";
    static backgroundGrey = '#E4E4E4';
    static white = '#FFFFFF';
    static black = '#000000';
}
import React from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import Colours from "../../common/Colours";
import EKasiQuestionDropDownItem from "../../common/components/EKasiQuestionDropDownItem";
import Faqs from "../../common/FAQs";
import { Typography } from "@mui/material";
import LocalizedStrings from "../../localization/Strings";


export const FAQs = () => {

    return (
        <>
        <Helmet>
            <title> Help | FAQs </title>
            <meta name="description" content="Help me apply for a cash loan online." />
        </Helmet>

        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor: Colours.white, padding: 40, marginTop: 10, marginBottom: 10}}>
            <Typography sx={{ display: 'inline', textAlign: 'left', p: 5, typography: 'h4', color: Colours.black, fontWeight: '300' }}>
                {`${LocalizedStrings.eKasiCredit} | `}
                <Typography sx={{ display: 'inline', typography: 'h4', color: Colours.viridianGreen, fontWeight: 'bold' }}>
                    {LocalizedStrings.FAQs}            
                </Typography> 
            </Typography>                {
                    Faqs.map((article) => (
                        <EKasiQuestionDropDownItem 
                            title={article.title} 
                            details={article.detail}
                            key={article.id}
                        />
                    ))
                }
        </Box>
        
        </>
    )
}

export default FAQs;
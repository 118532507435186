import React, {useState, useEffect} from 'react'
import {InputLabel, Select, MenuItem, Button, Grid, Typography, Box} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../../common/FormInput';
import Strings from '../../localization/Strings';
import Colours from '../../common/Colours';
import useCheckMobile from '../../common/useCheckMobile';

const AddressForm = ({ onPrev, onSubmit, defaultData }) => {
    // const baseUrl = process.env.REACT_APP_BASE_URL;
    const isMobile = useCheckMobile();
    const [country, setShippingCountry] = useState('');
    const [province, setShippingSubdivision] = useState('');
    const [address1, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const methods = useForm();
    const isUserDashboard =  (typeof defaultData?.customer !== "undefined") 

    const _countries = [{id: 'ZA', label: 'South Africa'}];
    const _subdivisions = [{id: 'KZN', label: 'Kwazulu-natal'}, {id: 'EC', label: 'Eastern Cape'}, {id: 'WC', label: 'Western Cape'}, {id: 'Ga', label: 'Gauteng'},  {id: 'LP', label: 'Limpopo'}, {id: 'MP', label: 'Mpumalanga'}, {id: 'NC', label: 'Northern Cape'}, {id: 'NW', label: 'North West'},  {id: 'FS', label: 'Free State'} ];

    useEffect(() => {
        // eslint-disable-next-line valid-typeof
        if(typeof defaultData?.address !== "undefined" || isUserDashboard) {
            setShippingCountry(defaultData?.address?.country || defaultData.customer.country)
            setShippingSubdivision(defaultData?.address?.province || defaultData.customer.province)
            setAddress(defaultData?.address?.address1 ||  defaultData.customer.address_1)
            setCity(defaultData?.address?.city ||  defaultData.customer.city)
            setZip(defaultData?.address?.zip ||  defaultData.customer.postal_code)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
         <Typography style={{ fontWeight: 'bold',color: Colours.viridianGreen, padding: 10 }} textAlign={!isMobile ? 'center' : 'left'} >{Strings.address}</Typography>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => {
                    if(isUserDashboard) {
                        const updatedCustomerData = {
                            ...defaultData.customer,
                            address1,
                            city,
                            zip,
                            province,
                            country 
                        };
                        onSubmit(updatedCustomerData);
                        return
                      }
                   
                   onSubmit({
                       address: {
                           address1,
                           city,
                           zip,
                           province,
                           country
                       }
                   });
                })}>
                    <Grid container spacing={3}>
                        <FormInput defaultValue={defaultData?.address?.address1 || defaultData?.customer?.address_1} onChange={(e) => setAddress(e.target.value)} required name='address1' label={Strings.address} />
                        <FormInput defaultValue={defaultData?.address?.city || defaultData?.customer?.city} onChange={(e) => setCity(e.target.value)}  required name='city' label={Strings.city}/>
                        <FormInput defaultValue={defaultData?.address?.zip || defaultData?.customer?.postal_code} onChange={(e) => setZip(e.target.value)}  type="number" required name='zip' label={Strings.postalCode}/>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}> 
                            <InputLabel >{Strings.country}</InputLabel>
                            <Select disabled={isUserDashboard}  value={country} fullWidth onChange={(e) => setShippingCountry(e.target.value)}>
                                {_countries.map((country) => (
                                        <MenuItem key={country.id} value={country.id}>
                                           {country.label}
                                        </MenuItem>
                                ))}
                                </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} > 
                            <InputLabel>{Strings.province}</InputLabel>
                            <Select value={province} fullWidth onChange={(e) => {
                                setShippingSubdivision(e.target.value);
                            }}>
                                {_subdivisions.map((subdivision) => (
                                        <MenuItem key={subdivision.id} value={subdivision.id}>
                                           {subdivision.label}
                                        </MenuItem>
                                ))}
                                </Select>
                        </Grid>
                    </Grid>
                    <br />
                    <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',}}>
                        {
                           isUserDashboard ?
                                null 
                            :
                                <Button onClick={onPrev} style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{Strings.back}</Button>
                           
                        }
                        <Button type="submit" style={{ backgroundColor: Colours.variantViridianGreen, padding: 10, marginLeft: 7 }} fullWidth disableElevation variant="contained">{isUserDashboard ? Strings.update : Strings.next}</Button>
                    </Box>
                </form>
            </FormProvider>
        </>
    ) 
}

export default AddressForm

import React from 'react';
import { Helmet } from "react-helmet"
import Carousel from "react-material-ui-carousel"
import Colours from "../../common/Colours"
import LocalizedStrings from "../../localization/Strings"
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import useCheckMobile from "../../common/useCheckMobile";
import { useNavigate } from "react-router-dom";
import { CloudinaryContext, Image } from 'cloudinary-react';

const Landing = () => {
    const isMobile = useCheckMobile();
    const navigate = useNavigate();
    const images = [
        {
            buttonTitle: LocalizedStrings.applyNow,
            publicUrl: "ekasicreditassets/getcredit_keo3pe.png",
            navigationRoute: "/application",
        },
        {
            buttonTitle: LocalizedStrings.learnMore,
            publicUrl: "ekasicreditassets/creditcheckout_uifxqq.png",
            navigationRoute: "/merchant_services",
        }
    ];

    const Hero = ({buttonTitle, publicUrl, navigationRoute}) => {
        return (
            <Box style={{backgroundColor: Colours.white}}>
                <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button 
                    onClick={() => {navigate(navigationRoute)}}
                    style={{ maxWidth: "50%", paddingLeft: 50, paddingRight: 50, margin: 15, borderRadius: isMobile ? 5 : 10 }}
                    variant="outlined"                        
                    >
                    {buttonTitle}
                </Button> 
                </Box>
                <Image
                    width="100%"
                    publicId={publicUrl}> 
                </Image>
            </Box>
        )
    };

    return (
        <>
            <Helmet>
                <title>{LocalizedStrings.home}</title>
                <meta name="description" content={"Loans"} />
            </Helmet>
            <CloudinaryContext cloudName="dfu4oiijg">
                <Carousel
                    fullHeightHover={true}
                    width="100%"
                    autoPlay={true}
                    style={{ display: 'flex', flexDirection: 'column', backgroundColor: Colours.backgroundGrey}}
                >
                     {images.map((image) => {
                        return (<Hero buttonTitle={image.buttonTitle} publicUrl={image.publicUrl} navigationRoute={image.navigationRoute}/>)
                     })}
                </Carousel>
             </CloudinaryContext>
        </>
    )
}

export default Landing;
import React from 'react';
import { Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Colours from '../Colours';
import LocalizedStrings from '../../localization/Strings';
import { Link } from 'react-router-dom';

const useStyles = styled(theme => ({
  root: {
    borderRadius: 15,
    minWidth: 256,
    textAlign: 'center',
    marginTop: 10,
  },
  header: {
    textAlign: 'center',
    spacing: 10,
  },
  list: {
    padding: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function EKasiPlanCard({title, body}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography sx={{ display: 'inline', textAlign: 'left', p: 7, typography: 'h5', color: Colours.backgroundGrey, fontWeight: '400',  }}>
            {`${LocalizedStrings.eKasiCredit} | `}
            <Typography sx={{ display: 'inline', typography: 'h5', color: Colours.viridianGreen, fontWeight: 'bold' }}>
            {title}       
            </Typography> 
       </Typography>
      <Divider variant="middle" />
      <CardContent>
      <Typography sx={{ variant: 'body2', color: Colours.black, fontWeight: '300', textAlign: 'center' }}>
        <div className={classes.list} dangerouslySetInnerHTML={{ __html: body }}></div>    
      </Typography>    
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={classes.action}>
        <Button component={Link} to="/support" variant="contained" color="primary" className={classes.button}>
          {LocalizedStrings.getInTouch}
        </Button>
      </CardActions>
    </Card>
  );
};